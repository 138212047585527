import React from "react";
// @material-ui/core components
// @material-ui/icons

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";


// react bootstrap components

import Slide from "@material-ui/core/Slide";
import { makeStyles } from "@material-ui/core/styles";
import styles from 'assets/jss/material-kit-react/views/landingPageSections/glanceStyle';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

Transition.displayName = "Transition";

const useStyles = makeStyles(styles)

const GlanceSection = (props) => {
  const classes = useStyles();
  return (
    <div>
    <div className="rounded-0" style={{backgroundColor: "#A1AADA", backgroundImage: "linear-gradient(315deg, #A1AADA 0%, #d9d9d9 100%)", textAlign: "center"}}>
      <GridContainer style={{minHeight: "150px"}}>
        <GridItem lg="3" md="12" sm="12">
          <h3 className="mt-5 font-weight-bold">15 years</h3>
          <h5 style={{fontSize: "12px"}}>managing client money</h5>
        </GridItem>
        <GridItem lg="3" md="12" sm="12">
          <h3 className="mt-5 font-weight-bold">Focused factor strategies</h3>
          <h5 style={{fontSize: "12px"}}>developed using time-tested methods</h5>
        </GridItem>
        <GridItem lg="3" md="12" sm="12">
          <h3 className="mt-5 font-weight-bold">45+ quantitative strategies</h3>
          <h5 style={{fontSize: "12px"}}>used in our process</h5>
        </GridItem>
        <GridItem lg="3" md="12" sm="12" className={classes.lastGrid}>
          <h3 className="mt-5 font-weight-bold">3000+ stocks and ETFs</h3>
          <h5 style={{fontSize: "12px"}}>in our investable universe</h5>
        </GridItem>
      </GridContainer>
    </div>
    </div>
  );
}

export default GlanceSection;
