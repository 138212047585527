import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Card from '@material-ui/core/Card';

// @material-ui/icons
// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Parallax from "components/Parallax/Parallax.js";
import GlanceBanner from "components/GlanceBanner/GlanceBanner";

// flat design images
import PhilosophyImage from "assets/img/undraws/philosophy.svg";
import ProcessImage from "assets/img/undraws/process.svg";
import ServerImage from "assets/img/undraws/server.svg";
import StockImage from "assets/img/undraws/stock.svg";
import GuruImage from "assets/img/undraws/guru.svg";
import TimeImage from "assets/img/undraws/time.svg";

// style sheets
import styles from "assets/jss/material-kit-react/views/philosophyPage.js";
import KitSection from "views/LandingPage/Sections/KitSection";
import { MetaTags } from "react-meta-tags";

const useStyles = makeStyles(styles);


export default function PhilosophyPage(props) {
  const classes = useStyles();
  const { ...rest } = props;
  return (
    <div>
      <MetaTags>
        <title>Philosophy and Process | Validea Capital Management</title>
        <meta name="description" content="We have built sytematic models based on factors that academic research has shown leads to investment success. We follow them in a disciplined, unemotional and systematic manner that seeks to maximize returns and minimize risk." />
      </MetaTags>
      <Header
        color="transparent"
        brand="Validea Capital"
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 200,
          color: "white"
        }}
        {...rest}
      />
      <Parallax small filter image={require("assets/img/bg8.jpg")} />
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div>
          <div>
            <GlanceBanner
            title1="Identify"
            subtitle1="strategies backed up by academic research and real world results"
            title2="Follow"
            subtitle2="models in a disciplined, systematic manner"
            title3="Blend"
            subtitle3="uncorrelated strategies to manage risk"
            />
          </div>
          <div className={classes.container}>
            <GridContainer justify="center">
              <GridItem xs={12} sm={12} md={6}>
                <div className={classes.profile}>
                  <div className={classes.name}>
                    <h2 className={classes.title}>Philosophy</h2>
                  </div>
                </div>
              </GridItem>
            </GridContainer>
            <div className={classes.description}>
              <h5 style={{color: "black"}}>
              At Validea Capital, we believe in following investing approaches that have proven themselves over long periods of time and doing so in a systematic way to eliminate emotion and biases from the investment process. Instead of trying to reinvent the wheel, we have combed hundreds of academic research papers and other published writings to identify strategies with proven track records. We utilize these models together to build custom portfolios for our clients. We offer both equity only and risk-managed models. 
              </h5>
            </div>
            <GridContainer justify="center">
              <GridItem xs={12} sm={12} md={6} className={classes.navWrapper}>
                <Card className={classes.card}>
                  <img alt="" src={PhilosophyImage} className={classes.image} />
                  <h3>Follow Proven Strategies</h3>
                  <h5 className={classes.h5} style={{marginTop: "20px", marginLeft: "20px", marginRight: "20px"}}>
                  We have built systematic models based on factors that academic research has shown leads to investment success. We follow them in a disciplined, unemotional and systematic manner that seeks to maximize returns and minimize risk.
                  </h5>
                </Card>
              </GridItem>
              <GridItem xs={12} sm={12} md={6} className={classes.navWrapper}>
                <Card className={classes.card}>
                  <img alt="" src={StockImage} className={classes.image} style={{width: "auto", height: "190px"}}/>
                  <h3>Blend Strategies to Manage Risk</h3>
                  <h5 className={classes.h5} style={{marginTop: "20px", marginLeft: "20px", marginRight: "20px"}}>
                  Each portfolio is a blend of multiple strategies that are combined together using correlation analysis. By blending multiple strategies and methods in the creation of our portfolios, our investment strategy seeks to generate excess returns while eliminating risk specific to individual strategies, styles and asset classes.
                  </h5>
                </Card>
              </GridItem>
              <GridItem xs={12} sm={12} md={6} className={classes.navWrapper}>
                <Card className={classes.card}>
                  <img alt="" src={GuruImage} className={classes.image} />
                  <h3>Disciplined Management Framework</h3>
                  <h5 className={classes.h5} style={{marginTop: "20px", marginLeft: "20px", marginRight: "20px"}}>
                  We follow a systematic portfolio management process that ensures that current positions continue to score highly using our quantitative models. Efficiency is maximized by using an intelligent selling process that weighs each holding's quantitative score against the tax and turnover implications of sale for the portfolio. Our system is automated to eliminate the emotion and bias that studies have shown can be a detriment to long-term results.
                  </h5>
                </Card>
              </GridItem>
              <GridItem xs={12} sm={12} md={6} className={classes.navWrapper}>
                <Card className={classes.card}>
                  <img alt="" src={TimeImage} className={classes.image}/>
                  <h3 style={{marginTop: "45px"}}>Intelligent Long Term Investing</h3>
                  <h5 className={classes.h5} style={{marginTop: "20px", marginLeft: "20px", marginRight: "20px"}}>
                  We are strong believers that long-term investing is the best way to outperform the market. However, we don’t believe that an investor has to hold individual positions for a period of years to be considered a long-term investor. We think long-term adherence to an investing strategy is much more important. That is why our system is set up with structured review periods to ensure that the top fundamental scoring ideas are included in each portfolio, with a heavy emphasis on maximizing tax efficiency over the long term.
                  </h5>
                </Card>
              </GridItem>
            </GridContainer>
          </div>
          <div>
            <KitSection/>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
