import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import Card from '@material-ui/core/Card';

// undraws
import ResearchImage from '../../assets/img/undraws/research.svg';
import YogaImage from '../../assets/img/undraws/yoga.svg';
import WalletImage from '../../assets/img/undraws/wallet.svg';
import CustomImage from '../../assets/img/undraws/customSurvey.svg';
import BearImage from '../../assets/img/undraws/bear.svg';
import ManImage from "../../assets/img/undraws/businessman.svg";
import SocialImage from '../../assets/img/undraws/social.svg';

// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import Button from "components/CustomButtons/Button.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Parallax from "components/Parallax/Parallax.js";
import GlanceBanner from 'components/GlanceBanner/GlanceBanner';


import styles from "assets/jss/material-kit-react/views/roboAdvisorPage.js";

import { withStyles } from '@material-ui/styles';
import KitSection from "views/LandingPage/Sections/KitSection";
import { MetaTags } from "react-meta-tags";

// const useStyles = makeStyles(styles);

class RoboAdvisorPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      values: [
        {name: "age",
        }
      ]
    }
  }
  render() {
  const { ...rest } = this.props;
  const { classes } = this.props;
  return (
    <div>
      <MetaTags>
        <title>Validea Capital Management - Robo Advisor</title>
        <meta name="description" content="A digital advisor that invests using the strategies of wall street legends."/>
      </MetaTags>
      <Header
        color="transparent"
        brand="Material Kit React"
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 200,
          color: "white"
        }}
        {...rest}
      />
      <Parallax small filter image={require("assets/img/bg8.jpg")} />
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div>
          <div>
            <GlanceBanner
              title1="Institutional quality"
              subtitle1="asset allocation strategies"
              title2="Low fee"
              subtitle2="automated offering"
              title3="Stock selection"
              subtitle3="using proven factors"
            />
          </div>
          <div className={classes.container}>
            <GridContainer justify="center">
              <GridItem lg="8" sm="12" md="12">
                <h2 style={{marginTop: "70px"}} className="text-center font-weight-bold">Validea Legends Advisor</h2>
              </GridItem>
              <GridItem lg="8" sm="12" md="12">
                <div className="text-center mb-2 mt-n2">A Digital Advisor That Invests Using the Strategies of Wall Street Legends</div>
              </GridItem>
            </GridContainer>
            <GridContainer justify="center">
              <GridItem lg="8" sm="12" md="12">
                <h3 className="mt-5 text-center font-weight-bold">Our Core Principles</h3>
              </GridItem>
            </GridContainer>
            <GridContainer justify="center">
              <GridItem lg="3" className={classes.grid}>
                <Card className={classes.card}>
                  <img alt="" className={classes.image} src={ResearchImage} />
                </Card>
              </GridItem>
              <GridItem lg="9" className={classes.grid}>
                <Card className={classes.card}>
                  <h4 className="font-weight-bold">Follow Proven Investment Strategies</h4>
                  <h5 className="text-justify">Our proprietary investment system utilizes factor-based strategies that have produced market outperformance over extended periods of time. The Validea Legends Advisor selects stocks using over 40 investment models based on historically successful investors, and also invests in actual guru holdings via Berkshire Hathaway, which has returned over 20% annually since its inception.</h5>
                </Card>
              </GridItem>
              <GridItem lg="3" className={classes.grid}>
                <Card className={classes.card}>
                  <img alt="" className={classes.image} src={YogaImage} />
                </Card>
              </GridItem>
              <GridItem lg="9"  className={classes.grid}>
                <Card className={classes.card}>
                  <h4 className="font-weight-bold">Implement the Investment Process with Discipline</h4>
                  <h5 className="text-justify">Emotion is the biggest obstacle that prevents most investors from achieving their long-term investing goals. The Validea Legends Advisor eliminates emotion from the investing process by following a disciplined investing system that utilizes quantitative stock selection and asset allocation models, and that regularly rebalances your portfolio in order to invest in areas of the market where relative value is greatest.</h5>
                </Card>
              </GridItem>
              <GridItem lg="3" className={classes.grid}>
                <Card className={classes.card}>
                  <img alt="" style={{maxHeight: "214px"}} className={classes.image} src={WalletImage} />
                </Card>
              </GridItem>
              <GridItem lg="9" className={classes.grid}>
                <Card className={classes.card}>
                  <h4 className="font-weight-bold">Focus on Fees</h4>
                  <h5 className="text-justify">Unlike most Robo Advisors, our reported fees incorporate all costs, including our 0.50% management fee, trading commissions, and the fees of the ETFs we utilize in our portfolios. We limit fees by investing in best of breed low cost ETFs and by investing in Berkshire Hathaway A shares, which offer our investors access to the stock picking prowess of Warren Buffett without a management fee. Total fees for Validea Legends Advisor (including underlying ETF fees and all trading commissions) range from 0.53% for our Conservative Portfolio to 0.80% for our Aggressive Portfolio.</h5>
                </Card>
              </GridItem>
              <GridItem lg="3" className={classes.grid}>
                <Card className={classes.card}>
                  <img alt="" style={{maxHeight: "187px"}} className={classes.image} src={CustomImage} />
                </Card>
              </GridItem>
              <GridItem lg="9" className={classes.grid}>
                <Card className={classes.card}>
                  <h4 className="font-weight-bold">Customize a Portfolio For Each Investor</h4>
                  <h5 className="text-justify">We recognize that each investor has different goals and tolerance for risk. The Validea Legends Advisor builds a customized portfolio for each investor based on their personal risk profile. Each client will complete a comprehensive risk questionaire upon opening their account and our client allocation system will use that information to build a customized portfolio that meets their individual needs. And if needed, our advisors are always available to help guide and advise you over the long-term.</h5>
                </Card>
              </GridItem>
              <GridItem lg="3" className={classes.grid}>
                <Card className={classes.card}>
                  <img alt="" style={{maxHeight: "213px", transform: "scale(0.6)"}} className={classes.image} src={BearImage} />
                </Card>
              </GridItem>
              <GridItem lg="9" className={classes.grid}>
                <Card className={classes.card}>
                  <h4 className="font-weight-bold">Manage Risk Via Downside Protection and Uncorrelated Assets</h4>
                  <h5 className="text-justify">For many years, leading endowment funds have recognized that the use of uncorrelated asset classes can improve risk-adjusted returns. The Validea Legends Advisor incorporates a variety of uncorrelated asset classes where appropriate to limit risk. In addition, our proprietary market rotation system will reduce exposure in the higher risk asset classes in our portfolios when our technical criteria indicate further losses may be coming. And when market valuations reach very high levels, we will also employ a small position in tail risk strategies to limit downside risk.</h5>
                </Card>
              </GridItem>
              <GridItem lg="3" className={classes.grid}>
                <Card className={classes.card}>
                  <img alt="" className={classes.image} src={ManImage} />
                </Card>
              </GridItem>
              <GridItem lg="9" className={classes.grid}>
                <Card className={classes.card}>
                  <h4 className="font-weight-bold">Provide Access to a Person - If You Want It</h4>
                  <h5 className="text-justify">Unlike other robo advisors that either do not provide access to investment representatives or charge an additional fee for it, the Validea Legends Advisor offers unlimited access to our investment consultants free of charge. So if you want access to an advisor, you can have it, but if you are more comfortable doing things on your own, you have that option too.</h5>
                </Card>
              </GridItem>
            </GridContainer>
            <GridContainer justify="center">
              <GridItem lg="12" md="12" sm="12">
                <h3 className="text-center font-weight-bold pb-3 pt-5">Validea Legends vs. the Competition</h3>
                <div style={{overflowX: "scroll"}}>
                  <table className={classes.table} style={{marginLeft: "auto", marginRight: "auto"}}>
                  <thead>
                    <tr>
                      <th></th>
                      <th className="text-center">Validea Legends Advisor</th>
                      <th className="text-center">Typical Robo Advisor</th>
                      <th className="text-center">Typical Financial Advisor</th>
                    </tr>
                  </thead>
                  <tbody>
                      <tr>
                        <td className="font-weight-bold">Stock Selection</td>
                        <td className="text-center">Active stock selection using strategies of Wall Street Legends</td>
                        <td className="text-center">Passive index exposure weighting stocks by market capitalization</td>
                        <td className="text-center">Stock selection outsourced to outside managers or funds</td>
                      </tr>
                      <tr>
                        <td className="font-weight-bold">Asset Allocation</td>
                        <td className="text-center">Modeled after leading institutions and endowments</td>
                        <td className="text-center">Mostly standard stocks and bonds</td>
                        <td className="text-center">Varies</td>
                      </tr>
                      <tr>
                        <td className="font-weight-bold">Access to Professionals</td>
                        <td className="text-center">Yes</td>
                        <td className="text-center">Varies, often increases fees</td>
                        <td className="text-center">Yes</td>
                      </tr>
                      <tr>
                        <td className="font-weight-bold">ETFs and Individual Stocks</td>
                        <td className="text-center">Yes (inc. Berkshire Hathaway)</td>
                        <td className="text-center">Varies, mostly ETFs</td>
                        <td className="text-center">Yes</td>
                      </tr>
                      <tr>
                        <td className="font-weight-bold">Downside Protection</td>
                        <td className="text-center">Risk Managed Rotational Model</td>
                        <td className="text-center">Mostly long only, fully invested</td>
                        <td className="text-center">Mostly long only, fully invested</td>
                      </tr>
                      <tr>
                        <td className="font-weight-bold">Avg. Account Minimum</td>
                        <td className="text-center">$25,000</td>
                        <td className="text-center">$16,700</td>
                        <td className="text-center">Typically $100,000+</td>
                      </tr>
                      <tr>
                        <td className="font-weight-bold">Trading Fees</td>
                        <td className="text-center">Included in management fee</td>
                        <td className="text-center">Varies</td>
                        <td className="text-center">Varies</td>
                      </tr>
                  </tbody>
                  </table>
                  </div>
                  <p style={{fontSize: "10px"}} className="text-center">* Management fee on $100,000 investment (exclusive of fund fees). Fees and account size information from https://valuepenguin.com/comparing-fees-robo-advisors</p>
                </GridItem>
            </GridContainer>
            <GridContainer className="mt-5 pb-3" justify="center">
              <GridItem lg="12" md="12" sm="12">
                <h3 className="text-center font-weight-bold pb-2">Main Advantages of Validea Legends Advisor</h3>
              </GridItem>
              <GridItem lg="3" className={classes.grid}>
                <Card className={classes.card}>
                  <img alt="" style={{maxHeight: "208px"}} className={classes.image} src={SocialImage} />
                </Card>
              </GridItem>
              <GridItem lg="9" className={classes.grid}>
                <Card className={classes.card}>
                <ul className="text-center" style={{listStyleType: "none", lineSpacing: 1.6}}>
                  <li className="pt-2 pb-2">
                    Follow Strategies of Investment Legends
                  </li>
                  <li className="pb-2">
                    Utilize Asset Allocation Strategies Used by Leading Endowments
                  </li>
                  <li className="pb-2">
                    Significantly Lower Fees Than Active Management
                  </li>
                  <li className="pb-2">
                    Low Minimum $25,000 Initial Investment
                  </li>
                  <li className="pb-2">
                    Access to Investment Professionals if Needed
                  </li>
                  <li>
                    Real Time Account Access and Transparency
                  </li>
                </ul>
                </Card>
              </GridItem>
            </GridContainer>
            <GridContainer justify="center">
              <GridItem lg="12" md="12" sm="12" className="pt-5 pb-5 text-center">
                <Button href="/robo-advisor-questionaire" style={{backgroundColor: "#F34436", fontSize: "18px", fontWeight: "bold"}}>Get Started</Button>
              </GridItem>
            </GridContainer>
          </div>
          <div>
            <KitSection/>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
  }
}

export default withStyles(styles)(RoboAdvisorPage);
