import React, { useEffect, useState } from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import { Redirect, useParams } from "react-router-dom";

// @material-ui/icons
// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Parallax from "components/Parallax/Parallax.js";

// style sheets
import styles from "assets/jss/material-kit-react/views/equityPage.js";

// urls
import APIUrl from "../../utils/APIUrl";
// axios
import axios from "axios";

const useStyles = makeStyles(styles);

const UnsubscribePage = (props) => {
    const [hasMadeAPIRequest, setHasMadeAPIRequest] = useState(false);
    const [requestStatus, setRequestStatus] = useState('PENDING');
    const { email } = useParams();

    const classes = useStyles();
    const { ...rest } = props;
    var url = APIUrl() + 'api/unsubscribe';

    useEffect(() => {
      if (email && !hasMadeAPIRequest) {
        axios.post(url, {
          email: email
        })
        .then(response => {
          if (response.status === 200) {
            setRequestStatus('SUCCESS');
          }
          else {
            setRequestStatus('FAILURE');
          }
          setHasMadeAPIRequest(true);
        })
        .catch(error => {
          setRequestStatus('FAILURE');
          setHasMadeAPIRequest(true);
        });
      }
    }, [hasMadeAPIRequest, email, url]);

    if (!email) {
      return <Redirect to="/"/>
    }

    return (
      <div>
        <Header
          color="transparent"
          brand="Validea Capital"
          rightLinks={<HeaderLinks />}
          fixed
          changeColorOnScroll={{
            height: 200,
            color: "white"
          }}
          {...rest}
        />
        <Parallax small filter image={require("assets/img/bg8.jpg")} />
        <div className={classNames(classes.main, classes.mainRaised)}>
          <div>
            <div className={classes.container}>
              <GridContainer justify="center">
                <GridItem lg="12" md="12" sm="12">
                  {requestStatus === 'PENDING' && (
                    <h3 className="pt-5 pb-5">Please wait...</h3>
                  )}
                  {requestStatus === 'SUCCESS' && (
                    <h1 className="pt-5 pb-5">You have successfully unsubscribed!</h1>
                  )}
                  {requestStatus === 'FAILURE' && (
                    <h1 className="pt-5 pb-5">We were unable to unsubscribe your account.</h1>
                  )}
                </GridItem>
              </GridContainer>
              <div>
            </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
}

export default UnsubscribePage;