import { container, title } from "assets/jss/material-kit-react.js";

import imagesStyle from "assets/jss/material-kit-react/imagesStyles.js";

const equityPageStyle = {
  container,
  profile: {
    textAlign: "center",
    "& img": {
      maxWidth: "160px",
      width: "100%",
      margin: "0 auto",
      transform: "translate3d(0, -50%, 0)"
    }
  },
  description: {
    margin: "1.071rem auto 0",
    maxWidth: "800px",
    color: "#999",
    textAlign: "center !important"
  },
  name: {
    marginTop: "0px"
  },
  ...imagesStyle,
  main: {
    background: "#FFFFFF",
    position: "relative",
    zIndex: "3"
  },
  mainRaised: {
    margin: "-60px 30px 0px",
    borderRadius: "6px",
    boxShadow:
      "0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)"
  },
  title: {
    ...title,
    display: "inline-block",
    textAlign: "center",
    position: "relative",
    marginTop: "30px",
    minHeight: "32px",
    textDecoration: "none"
  },
  socials: {
    marginTop: "0",
    width: "100%",
    transform: "none",
    left: "0",
    top: "0",
    height: "100%",
    lineHeight: "41px",
    fontSize: "20px",
    color: "#999"
  },
  navWrapper: {
    margin: "20px auto 50px auto",
    textAlign: "center"
  },
  image: {
    width: "100%",
    height: "auto",
  },
  card: {
    transition: ".2s",
    boxShadow:" 0 10px 20px rgba(0,0,0,0.20), 0 10px 8px rgba(0,0,0,0.1)"
  },
  label: {
    marginTop: "32px",
    float: "right"
  },
  '@media (max-width: 768px)': {
    label: {
      float: "none",
      alignSelf: "center"
    }
  },
  listImage: {
    width: "100%",
    height: "auto"
  },
  '@media (min-width: 768px)': {
      columnRight: {
        marginTop: "50px"
      }
  }
}

export default equityPageStyle;
