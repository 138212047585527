export default function descriptions(portfolioName) {
    switch(portfolioName) {
        case 'Ultra Conservative':
            return "The Ultra Conservative portfolio seeks to stabilize returns by investing most of its assets in fixed income vehicles, coupled with a smaller exposure to stocks, commodities and real estate. The stock exposure of the portfolio is managed via our ETF, which invests in a 100 stock portfolio selected using our guru models, and a separate position in Berkshire Hathaway. Bonds and uncorrelated assets make up the majority of the portfolio at its target allocation. Exposure to uncorrelated asset classes, such as gold and timber, seeks to limit risk and smooth returns of the portfolio over time. Combining non-correlated asset classes is a core strategy for many top pension and university endowment plans and we have sought to mirror that approach in this portfolio. This portfolio also utilizes our Market Rotation system. This system seeks to reduce exposure to riskier asset classes when market trends become negative in an effort to limit losses during bear market periods."
        case 'Conservative':
            return "The Conservative portfolio seeks to stabilize returns by investing most of its assets in fixed income vehicles, coupled with a smaller exposure to stocks, commodities and real estate. The stock exposure of the portfolio is managed via our ETF, which invests in a 100 stock portfolio selected using our guru models, and a separate position in Berkshire Hathaway. Bonds and uncorrelated assets make up the majority of the portfolio at its target allocation. Exposure to uncorrelated asset classes, such as gold and timber, seeks to limit risk and smooth returns of the portfolio over time. Combining non-correlated asset classes is a core strategy for many top pension and university endowment plans and we have sought to mirror that approach in this portfolio. This portfolio also utilizes our Market Rotation system. This system seeks to reduce exposure to riskier asset classes when market trends become negative in an effort to limit losses during bear market periods."
        case 'Balanced':
            return "The Balanced portfolio seeks to couple growth and stability by investing in a variety of asset classes, including stocks, bonds, commodities and real estate. The stock exposure of the portfolio is managed via our ETF, which invests in a 100 stock portfolio selected using our guru models, and a separate position in Berkshire Hathaway. Bonds and uncorrelated assets make up the majority of the portfolio at its target allocation. Exposure to uncorrelated asset classes, such as gold and timber, seeks to limit risk and smooth returns of the portfolio over time. Combining non-correlated asset classes is a core strategy for many top pension and university endowment plans and we have sought to mirror that approach in this portfolio. This portfolio also utilizes our Market Rotation system. This system seeks to reduce exposure to riskier asset classes when market trends become negative in an effort to limit losses during bear market periods."
        case 'Moderate':
            return "The Moderate portfolio seeks to couple growth and stability by investing in a variety of asset classes, including stocks, bonds, commodities, real estate and a strategy that seeks to mirror the returns of private equity using public companies. The main stock exposure of the portfolio is managed via our ETF, which invests in a 100 stock portfolio selected using our guru models, and a separate position in Berkshire Hathaway. Bonds and uncorrelated assets make up a significant portion of the portfolio at its target allocation. Exposure to uncorrelated asset classes, such as gold and timber, seeks to limit risk and smooth returns of the portfolio over time. Combining non-correlated asset classes is a core strategy for many top pension and university endowment plans and we have sought to mirror that approach in this portfolio. This portfolio also utilizes our Market Rotation system. This system seeks to reduce exposure to riskier asset classes when market trends become negative in an effort to limit losses during bear market periods. "
        case 'Aggressive':
            return 'The Aggressive portfolio maintains a primary focus on long-term growth by investing primarily in stocks with a secondary exposure to other asset classes, including commodities, real estate and a strategy that seeks to mirror the returns of private equity using public companies. The main stock exposure of the portfolio is managed via our ETF, which invests in a 100 stock portfolio selected using our guru models, and a separate position in Berkshire Hathaway. This portfolio also utilizes our Market Rotation system. This system seeks to reduce exposure to riskier asset classes when market trends become negative in an effort to limit losses during bear market periods.'
        case 'Ultra Aggressive':
            return 'The Ultra Aggressive portfolio is our highest risk offering and seeks long-term growth by investing entirely in stocks. The main stock exposure of the portfolio is managed via our ETF, which invests in a 100 stock portfolio selected using our guru models, and a separate position in Berkshire Hathaway. This portfolio also utilizes our Market Rotation system. This system seeks to reduce exposure to riskier asset classes when market trends become negative in an effort to limit losses during bear market periods.'
        default:
            return ""
    }
}