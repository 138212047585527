import React from "react";
import { withRouter } from "react-router-dom";

// nodejs library that concatenates classes
import classNames from "classnames";

// @material-ui/core components
import TextField from '@material-ui/core/TextField';
import { FormControl, InputLabel, Select, MenuItem } from "@material-ui/core";

// @material-ui/icons
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeftOutlined';

// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import Button from "components/CustomButtons/Button.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Parallax from "components/Parallax/Parallax.js";
import SnackbarContent from 'components/Snackbar/SnackbarContent';

// questions data
import posToQuestionData from "./data/questions.js";

// form validate
import { ValidateForm } from "./ValidateForm.js";

// styles
import styles from "assets/jss/material-kit-react/views/profilePage.js";
import { withStyles } from '@material-ui/styles';

// urls
import APIUrl from "../../utils/APIUrl";

// api connection
import axios from "axios";

// constants
const QUESTIONSCOUNT = 11;

class RoboAdvisorQuestionairePage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      formValue: null,
      pos: 0,
      answers: [],
      submitted: false,
      currentValue: null,
      errMessage: "",
      redirectToResult: false,
      selectValue: "None",
      isBackDisabled: true
    }
    this.nextQuestion = this.nextQuestion.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.partialSubmit = this.partialSubmit.bind(this);
  }

  // show err message, flip to the next question, or flip to the result page
  nextQuestion = (e) => {
    e.preventDefault();
    const result = ValidateForm(this.state.pos, this.state.currentValue);

    if (result) {
      this.setState({errMessage: result})
      return 1;
    }

    if (this.state.pos < QUESTIONSCOUNT - 1) {
      this.setState({ errMessage: "" });
      this.setState({ pos: this.state.pos + 1 });
      this.state.answers.push(this.state.currentValue);
      
      document.getElementById("questionaire-form").value = "";
      this.setState({currentValue: null});
      this.setState({selectValue: "None"});

      // email submit
      if (this.state.answers.length === 1) {
        this.partialSubmit();
      }

      if (this.state.answers.length !== 0) {
        this.setState({isBackDisabled: false})
      }
    }
    else {
      this.state.answers.push(this.state.currentValue);
      this.setState({ errMessage: "" });
      
      
      var url = APIUrl() + 'api/roboRequest';
      
      axios.post(url, {
        answers: this.state.answers
      })
      .then(response => {
        this.props.history.push({
          pathname:"/robo-advisor-result",
          state: {data: response.data}
         });
      })
      .catch(error => {
        console.error(error);
      });
    }

  }

  handleChange = (event) => {
    this.setState({ selectValue: event.target.value })
    this.setState({ currentValue: event.target.value })
  }

  // stores email if the user doesn't finish survey
  partialSubmit = () => {
    if ((this.state.answers.length >= 1) && (this.state.answers.length < QUESTIONSCOUNT)) {
      
      var url = APIUrl() + 'api/roboRequestPartial';
      
      axios.post(url, {
        email: this.state.answers[0]
      })
      .then(response => {
        console.log("we are submitting your email");
      })
      .catch(error => {
        console.error(error);
      });
    }
  }

  handleBackButton = () => {
    if (this.state.answers.length !== 0) {
      this.state.answers.pop();
      this.setState({ pos: this.state.pos - 1 });
      this.setState({ currentValue: null, selectValue: "None" });
    }
  }

  render() {
  const { ...rest } = this.props;
  const { classes } = this.props;
  
  var questionData;
  if (this.state.answers.length >= 4) {
    questionData = posToQuestionData(this.state.pos, this.state.answers[4])
  }
  else {
    questionData = posToQuestionData(this.state.pos, 0)
  }

  let form;
  if (questionData.type === "dropdown") {
   form = (
    <FormControl id="questionaire-form" style={{minWidth: 120, marginTop: "10px"}}>
      <InputLabel id="questionaire-form" htmlFor="grouped-select">Select</InputLabel>
      <Select id="select-form" value={this.state.selectValue} required defaultValue="None" onChange={this.handleChange} >
        <MenuItem value="None">
          <em>None</em>
        </MenuItem>
        {questionData.options.map((option, index) =>
          <MenuItem value={index}>{option}</MenuItem>)}
      </Select>
    </FormControl>
    )
  }
  else {
    form = (
      <TextField
        required
        ref={el => this.inputTitle = el}
        id="questionaire-form"
        type={questionData.type}
        min={questionData.min}
        placeholder={questionData.placeholder}
        className={classes.textField}
        onChange={this.handleChange}
        margin="normal"
        size="medium"
        variant="outlined"
      />
    )
  }

  let errBlock;
  if (this.state.errMessage !== "") {
    errBlock = <SnackbarContent icon="info_outline" close message={this.state.errMessage} color="danger" />;
  }
  return (
    <div>
      <Header
        color="transparent"
        brand="Validea Capital"
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 200,
          color: "white"
        }}
        {...rest}
      />
      <Parallax small filter image={require("assets/img/bg8.jpg")} />
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div>
          {errBlock}
          <div className={classes.container}>
            <GridContainer justify="center">
              <GridItem xs={12} sm={12} md={6}>
                <div className={classes.profile}>
                  <div className={classes.name}>
                    <h3 className={classes.title}>Validea Legends Advisor Risk Assessment</h3>
                  </div>
                </div>
              </GridItem>
            </GridContainer>
            <div className={classes.description}>
              <p>
                Help us better understand your goals by obtaining your risk score and viewing your allocation. Please answer the following questions.
              </p>
            </div>
            <GridContainer justify="center" className={classes.formWrapper} style={{paddingBottom: "150px"}}>
              <GridItem xs={12} sm={12} md={4}>
                <h5 className={classes.label}>
                  {questionData.name}
                </h5>
              </GridItem>
                <form onSubmit={this.nextQuestion} >
                  <div className={classes.inputBox}>
                    {form}
                  </div>
                  <div className={classes.buttons}>
                    <Button onClick={this.handleBackButton} disabled={this.state.isBackDisabled} style={{outline: "none"}} className="mt-4 ml-4 pl-3 pr-2">
                      <KeyboardArrowLeft/><span className="mr-1">Back</span>
                    </Button>
                    <Button type="submit" style={{outline: "none"}} className="mt-4 ml-1 pl-3 pr-2">
                      Next<KeyboardArrowRight/>
                    </Button>
                  </div>
                </form>
            </GridContainer>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
  }
}

export default withRouter(withStyles(styles)(RoboAdvisorQuestionairePage));
