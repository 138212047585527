import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Card from '@material-ui/core/Card';

// @material-ui/icons
// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Parallax from "components/Parallax/Parallax.js";
import GlanceBanner from "components/GlanceBanner/GlanceBanner";

// flat design images
import PhilosophyImage from "assets/img/undraws/philosophy.svg";
import ProcessImage from "assets/img/undraws/process.svg";
import ServerImage from "assets/img/undraws/server.svg";
import StockImage from "assets/img/undraws/stock.svg";
import GuruImage from "assets/img/undraws/guru.svg";
import TimeImage from "assets/img/undraws/time.svg";

// style sheets
import styles from "assets/jss/material-kit-react/views/philosophyPage.js";
import KitSection from "views/LandingPage/Sections/KitSection";
import { MetaTags } from "react-meta-tags";

const useStyles = makeStyles(styles);


export default function WealthPage(props) {
  const classes = useStyles();
  const { ...rest } = props;
  return (
    <div>
      <MetaTags>
        <title>Wealth Management | Fairfield, West Hartford Connecticut | Validea Capital Management</title>
        <meta name="description" content="Wealth Management using proven systematic models. Tax Planning, Retirement Planning, Education Planning" />
      </MetaTags>
      <Header
        color="transparent"
        brand="Validea Capital"
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 200,
          color: "white"
        }}
        {...rest}
      />
      <Parallax small filter image={require("assets/img/bg8.jpg")} />
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div>
          <div>
            <GlanceBanner
            title1="Develop"
            subtitle1="an investment plan based on your personal goals "
            title2="Build"
            subtitle2="a systematic investment strategy to achieve them"
            title3="Customize"
            subtitle3="your plan based on your tax and estate planning needs "
            />
          </div>
          <div className={classes.container}>
            <GridContainer justify="center">
              <GridItem xs={12} sm={12} md={6}>
                <div className={classes.profile}>
                  <div className={classes.name}>
                    <h2 className={classes.title}>Wealth Management</h2>
                  </div>
                </div>
              </GridItem>
            </GridContainer>
            <div className={classes.description}>
              <h5 style={{color: "black"}}>
              You have worked hard to build your wealth. We will work with you to help you grow and keep it.
              <br></br><br></br>Whether you are looking for a comprehensive financial plan or have a specific area of your financial situation you would like to focus on, Validea Capital can help you develop a strategy to meet your goals. 
              <br></br><br></br>Our co-founder Jack Forehand is a CERTIFIED FINANCIAL PLANNER&trade; professional and will work with you to address your investment, retirement, education, tax and estate planning needs.  
              </h5>
            </div>
            <GridContainer justify="center">
              <GridItem xs={12} sm={12} md={6} className={classes.navWrapper}>
                <Card className={classes.card}>
                  <img alt="" src={PhilosophyImage} className={classes.image} />
                  <h3>Goals-Based Investment Planning</h3>
                  <h5 className={classes.h5} style={{marginTop: "20px", marginLeft: "20px", marginRight: "20px"}}>
                  We will work with you to identify and prioritize the goals that you wish to achieve and to build a systematic investment portfolio to help achieve them. 
                  </h5>
                </Card>
              </GridItem>
              <GridItem xs={12} sm={12} md={6} className={classes.navWrapper}>
                <Card className={classes.card}>
                  <img alt="" src={ProcessImage} className={classes.image} />
                  <h3 style={{marginTop: "40px"}}>Retirement Planning</h3>
                  <h5 className={classes.h5} style={{marginTop: "20px", marginLeft: "20px", marginRight: "20px"}}>
                  Whether you are just starting out on your retirement saving journey or are already in retirement, we will work with you to build a comprehensive retirement plan that helps generate your required income while also managing risk and taxes. 
                  </h5>
                </Card>
              </GridItem>
              <GridItem xs={12} sm={12} md={6} className={classes.navWrapper}>
                <Card className={classes.card}>
                  <img alt="" src={ServerImage} className={classes.image} />
                  <h3 style={{marginTop: "50px"}}>Tax Planning</h3>
                  <h5 className={classes.h5} style={{marginTop: "20px", marginLeft: "20px", marginRight: "20px"}}>
                  We can examine your overall tax situation to look for opportunities to reduce of defer taxes. Using strategies like income timing, asset location and charitable giving optimization, we will work to optimize your overall tax situation in the context of acheiving your long-term goals.
                  </h5>
                </Card>
              </GridItem>
              <GridItem xs={12} sm={12} md={6} className={classes.navWrapper}>
                <Card className={classes.card}>
                  <img alt="" src={StockImage} className={classes.image} style={{width: "auto", height: "190px"}}/>
                  <h3>Education Planning</h3>
                  <h5 className={classes.h5} style={{marginTop: "20px", marginLeft: "20px", marginRight: "20px"}}>
                  We can help you select the most appropriate education saving vehicle and develop an investment plan and strategy to achieve your education goals and help maximize financial aid when appropriate.
                  </h5>
                </Card>
              </GridItem>
              <GridItem xs={12} sm={12} md={6} className={classes.navWrapper}>
                <Card className={classes.card}>
                  <img alt="" src={GuruImage} className={classes.image} />
                  <h3>Behavioral Coaching</h3>
                  <h5 className={classes.h5} style={{marginTop: "20px", marginLeft: "20px", marginRight: "20px"}}>
                    Behavior is often the biggest reason that investors fail to meet their goals. We will work with you to develop a plan that you believe in and will be there to support you and keep you on target when the inevitable difficult times come. 
                  </h5>
                </Card>
              </GridItem>
              <GridItem xs={12} sm={12} md={6} className={classes.navWrapper}>
                <Card className={classes.card}>
                  <img alt="" src={TimeImage} className={classes.image}/>
                  <h3 style={{marginTop: "45px"}}>Ongoing Monitoring</h3>
                  <h5 className={classes.h5} style={{marginTop: "20px", marginLeft: "20px", marginRight: "20px"}}>
                  The development of a financial plan is just the beginning of the process to achieve long-term success. And plans often need to evolve as conditions change. As your goals and circumstances change and the markets go up and down, we will be there to help you navigate all of it.
                  </h5>
                </Card>
              </GridItem>
            </GridContainer>
          </div>
          <div>
            <KitSection/>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
