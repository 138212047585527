import React from "react";
// @material-ui/core components

// @material-ui/icons

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";



// react bootstrap components

import Slide from "@material-ui/core/Slide";


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

Transition.displayName = "Transition";

export default function GlanceBanner(props) {
  return (
    <div>
    <div className="rounded" style={{backgroundColor: "#A1AADA", backgroundImage: "linear-gradient(315deg, #A1AADA 0%, #d9d9d9 100%)", textAlign: "center"}}>
      <GridContainer style={{minHeight: "150px"}}>
        <GridItem lg="4" md="12" sm="12">
          <h3 className="mt-5 font-weight-bold">{props.title1}</h3>
          <h5 style={{fontSize: "12px"}}>{props.subtitle1}</h5>
        </GridItem>
        <GridItem lg="4" md="12" sm="12">
          <h3 className="mt-5 font-weight-bold">{props.title2}</h3>
          <h5 style={{fontSize: "12px"}}>{props.subtitle2}</h5>
        </GridItem>
        <GridItem lg="4" md="12" sm="12">
          <h3 className="mt-5 font-weight-bold">{props.title3}</h3>
          <h5 style={{fontSize: "12px"}}>{props.subtitle3}</h5>
        </GridItem>
      </GridContainer>
    </div>
    </div>
  );
}
