export function portfolioPercents(portfolio) {
    switch (portfolio) {
        case 'Ultra Conservative': 
        return [
            {
            color: "pink",
            title: "Stocks",
            value: 10,
            },
            {
            color: "darkgray",
            title: "Bonds",
            value: 57,
            },
            {
            color: "#A0A7D4",
            title: "Real Assets",
            value: 18,
            },
            {
            color: "darkorange",
            title: "Real Estate",
            value: 5,
            },
            {
            color: "green",
            title: "Equity Rotation",
            value: 5,
            },
            {
            color: "red",
            title: "Tail Risk",
            value: 5,
            },        
        ]
        case 'Conservative': 
            return [
                {
                color: "#CCCC00",
                title: "Real Assets",
                value: 15.5,
                },
                {
                color: "darkgray",
                title: "Equity Rotation",
                value: 15,
                },
                {
                color: "#A0A7D4",
                title: "Stocks",
                value: 15,
                },
                {
                color: "darkorange",
                title: "Bonds",
                value: 42,
                },
                {
                color: "green",
                title: "Real Estate",
                value: 7.5,
                },
                {
                color: "red",
                title: "Tail Risk",
                value: 5,
                },        
            ]
        case 'Balanced': 
            return [
                {
                color: "#CCCC00",
                title: "Real Assets",
                value: 14.5,
                },
                {
                color: "darkgray",
                title: "Equity Rotation",
                value: 15,
                },
                {
                color: "#A0A7D4",
                title: "Stocks",
                value: 25,
                },
                {
                color: "darkorange",
                title: "Bonds",
                value: 33,
                },
                {
                color: "green",
                title: "Real Estate",
                value: 7.5,
                },
                {
                color: "red",
                title: "Tail Risk",
                value: 5,
                },        
            ]
        case 'Moderate': 
            return [
                {
                color: "#CCCC00",
                title: "Real Assets",
                value: 14,
                },
                {
                color: "darkgray",
                title: "Equity Rotation",
                value: 17.5,
                },
                {
                color: "#A0A7D4",
                title: "Stocks",
                value: 27.5,
                },
                {
                color: "darkorange",
                title: "Bonds",
                value: 23,
                },
                {
                color: "green",
                title: "Real Estate",
                value: 9,
                },
                {
                color: "darkgreen",
                title: "Tail Risk",
                value: 5,
                },
                {
                color: "red",
                title: "Private Equity",
                value: 4,
                },                
            ]
        case 'Aggressive': 
            return [
                        {
                        color: "#CCCC00",
                        title: "Real Assets",
                        value: 10,
                        },
                        {
                        color: "darkgray",
                        title: "Equity Rotation",
                        value: 20,
                        },
                        {
                        color: "#A0A7D4",
                        title: "Stocks",
                        value: 55,
                        },
                        {
                        color: "green",
                        title: "Real Estate",
                        value: 10,
                        },
                        {
                        color: "red",
                        title: "Private Equity",
                        value: 5,
                        },        
                    ]
        case 'Ultra Aggressive': 
            return [
                {
                color: "darkgray",
                title: "Equity Rotation",
                value: 20,
                },
                {
                color: "#A0A7D4",
                title: "Stocks",
                value: 75,
                },
                {
                color: "red",
                title: "Private Equity",
                value: 5,
                },                
            ]
        default:
            break;
    }
}