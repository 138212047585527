import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Card from '@material-ui/core/Card';

// @material-ui/icons
// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import Button from "components/CustomButtons/Button.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Parallax from "components/Parallax/Parallax.js";
import GlanceBanner from 'components/GlanceBanner/GlanceBanner';

// flat design images
import CustomImage from '../../assets/img/undraws/custom.svg';
import CoreImage from '../../assets/img/undraws/core.svg'

// style sheets
import styles from "assets/jss/material-kit-react/views/equityPage.js";
import KitSection from "views/LandingPage/Sections/KitSection";
import { MetaTags } from "react-meta-tags";

const useStyles = makeStyles(styles);


export default function EquityPage(props) {
  const classes = useStyles();
  const { ...rest } = props;
  return (
    <div>
      <MetaTags>
        <title>Factor-Based Equity Portfolios | Validea Capital Management</title>
        <meta name="description" content="We build systematic investing models using the major investing factors, including value, momentum, quality, and low volatility." />
      </MetaTags>
      <Header
        color="transparent"
        brand="Validea Capital"
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 200,
          color: "white"
        }}
        {...rest}
      />
      <Parallax small filter image={require("assets/img/bg8.jpg")} />
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div>
          <div>
            <GlanceBanner 
              title1="45+ quantitative models"
              subtitle1="used in our process"
              title2="Concentrated approach"
              subtitle2="to maximize factor exposure"
              title3="Custom portfolio blends"
              subtitle3="for each investor"
            />
          </div>
          <div className={classes.container}>
            <GridContainer justify="center">
              <GridItem lg="8" md="12" sm="12">
                <h2 style={{marginTop: "70px"}} className="text-center font-weight-bold">Factor-Based Equity Portfolios</h2>
              </GridItem>
              <GridItem lg="5" md="12" sm="12">
                {/* <Card className={classes.card}>
                  <img className={classes.image} src={PortfolioImage} />
                </Card> */}
              </GridItem>
              <GridItem lg="8" md="12" sm="12" className="mt-2">
                <h5 style={{color: "black", maxWidth: "800px"}} className="text-justify">We build systematic models using investment factors that have proven themselves over time. Our models cover all the major investing factors, including value, momentum, quality, and low volatility. We offer a separately managed account for each client and every one of our investors receives an individual consultation with our investment team regarding their personal investing goals and risk tolerance before any of their money is invested.</h5>
              </GridItem>
            </GridContainer>
            <GridContainer justify="center" className="mt-5">
              <GridItem lg="5" md="12" sm="12">
                <h3 className="text-center font-weight-bold">Custom Models</h3>
                <Card className="d-flex justify-content-center shadow">
                  <img alt="" style={{width: "93%"}} className={classes.listImage} src={CustomImage} />
                </Card>
              </GridItem>
              <GridItem lg="7" md="12" sm="12">
                <Card style={{marginTop: "66px"}} className="p-4 shadow text-justify">
                  We offer a custom blend of the 45+ quantitative models we follow. Since each client has different needs and preferences, we can develop a customized approach that best fits each individual situation. For investors looking to limit the risk of following individual factors, we can combine uncorrelated factors to provide a smoother return over time. For clients looking for exposure to a specific factor like value or momentum, we can develop a combination of individual models that diversify exposure within the factor and limit the risk associated with a single approach. We can build concentrated portfolios for clients who seek aggressive factor exposure and more diversified models for those who like to track the market more closely.
                </Card>
              </GridItem>
            </GridContainer>
            <GridContainer justify="center" className="mt-5">
              <GridItem lg="5" md="12" sm="12">
                <h3 className="text-center font-weight-bold">Core Models</h3>
                <Card className="d-flex justify-content-center shadow">
                  <img alt="" style={{width: "88%"}} className={classes.listImage} src={CoreImage} />
                </Card>
              </GridItem>
              <GridItem lg="7" md="12" sm="12">
                <Card className="p-4 shadow text-justify" style={{marginTop: "66px"}}>
                  For clients who do not require a custom solution, we offer core models that blend our core factors like value, momentum, quality and low volatility. Our five core portfolios (Consensus, Select Blend, Top Gurus, Dividend Value and International) are appropriate for investors with above average risk tolerance and a long term time horizon. We also offer a Systematic Alpha strategy, which was developed in conjunction with leading academics. Our portfolios are managed with an intelligent tax management system, which seeks to maximize long term gains and tax efficiency. All portfolios are rebalanced on a 28 day cycle, which ensures disciplined and consistent active portfolio management and strategy adherence.
                </Card>
              </GridItem>
            </GridContainer>
            <GridContainer justify="center" className="mt-5 pb-5">
              <GridItem lg="12" md="12" sm="12" className="text-center">
                <Button
                  color="danger"
                  size="md"
                  style={{fontSize: "18px", fontWeight: "bold"}}
                  href="/contact"
                  rel="noopener noreferrer"
                >
                  <i className="fas fa-arrow-circle-right" />
                  Free Assessment
                </Button>
              </GridItem>
            </GridContainer>
            <div>
          </div>
          </div>
          <div>
            <KitSection/>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
