import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Card from '@material-ui/core/Card';

// @material-ui/icons
// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import Button from "components/CustomButtons/Button.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Parallax from "components/Parallax/Parallax.js";
import GlanceBanner from 'components/GlanceBanner/GlanceBanner';

// flat design images
import ContrastImage from '../../assets/img/undraws/contrast.svg';
import CurveImage from '../../assets/img/undraws/curve.svg';
import SwitchImage from '../../assets/img/undraws/switch.svg';

// style sheets
import styles from "assets/jss/material-kit-react/views/riskPage.js";
import KitSection from "views/LandingPage/Sections/KitSection";
import { MetaTags } from "react-meta-tags";

const useStyles = makeStyles(styles);


export default function RiskManagedPage(props) {
  const classes = useStyles();
  const { ...rest } = props;
  return (
    <div>
      <MetaTags>
        <title>Risk Managed ETF Portfolios | Validea Capital Management</title>
        <meta name="description" content="Our risk managed portfolios use proven quantitative strategies to blend asset classes such as stocks, bonds, commodities, gold and real estate."/>
      </MetaTags>
      <Header
        color="transparent"
        brand="Validea Capital"
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 200,
          color: "white"
        }}
        {...rest}
      />
      <Parallax small filter image={require("assets/img/bg8.jpg")} />
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div>
          <div>
            <GlanceBanner 
              title1="Multi-asset"
              subtitle1="approach built using correlation analysis"
              title2="Trend following"
              subtitle2="to limit risk in major drawdowns"
              title3="Factor and sector"
              subtitle3="rotation strategies using time-tested systems"
            />
          </div>
          <div className={classes.container}>
            <GridContainer justify="center">
              <GridItem lg="8" md="12" sm="12">
                <h2 style={{marginTop: "70px"}} className="text-center font-weight-bold">Risk-Managed Portfolios</h2>
              </GridItem>
              <GridItem lg="5" md="12" sm="12">
                {/* <Card className={classes.card}>
                  <img className={classes.image} src={InvestmentImage} />
                </Card> */}
              </GridItem>
              <GridItem lg="8" md="12" sm="12" className="mt-1">
                <h5 className="text-justify">Our risk managed use proven quantitative strategies to reduce risk relative to a long only equity portfolio.
                </h5>
              </GridItem>
            </GridContainer>
            <GridContainer justify="center" className="mt-5">
              <GridItem lg="4" md="12" sm="12">
                <h4 className="text-center font-weight-bold">Multi-Asset Class Portfolios</h4>
                <Card className="d-flex justify-content-center shadow">
                  <img alt="" className={classes.listImage} src={ContrastImage} />
                </Card>
              </GridItem>
              <GridItem lg="8" md="12" sm="12" style={{marginTop: "47px"}}>
                <Card className="p-4 shadow">
                  Our multi-asset class portfolios blend uncorrelated asset classes like bonds, commodities, real estate and gold with equities to generate more consistent returns over time. The asset classes are blended using quantitative methods developed using long-term academic research. We can develop a custom risk-managed solution for each client based on their personal goals and risk tolerance.
                </Card>
              </GridItem>
            </GridContainer>
            <GridContainer justify="center" className="mt-5">
              <GridItem lg="4" md="12" sm="12">
                <h4 className="text-center font-weight-bold">Trend Following Portfolios</h4>
                <Card className="d-flex justify-content-center shadow">
                  <img alt="" style={{width: "auto", height: "192px"}} src={CurveImage} />
                </Card>
              </GridItem>
              <GridItem lg="8" md="12" sm="12" style={{marginTop: "47px"}}>
                <Card className="p-4 shadow">
                  Our trend following models use a technical system based on long-term moving averages to limit risk during major market drawdowns. When market conditions deteriorate in any given asset class, the system will move to cash or lower risk assets to limit risk and will move back into the asset class when technical conditions improve. This approach is appropriate for investors who would like to capture the upside of financial markets, while limiting the risks associated with significant drawdowns.
                </Card>
              </GridItem>
            </GridContainer>
            <GridContainer justify="center" className="mt-5">
              <GridItem lg="4" md="12" sm="12">
                <h4 className="text-center font-weight-bold">Factor & Sector Rotation Portfolios</h4>
                <Card className="d-flex justify-content-center shadow">
                  <img alt="" style={{width: "auto", height: "167px"}} src={SwitchImage} />
                </Card>
              </GridItem>
              <GridItem lg="8" md="12" sm="12" style={{marginTop: "46px"}}>
                <Card className="p-4 shadow">
                  This strategy invests in the factors and sectors that score the highest using our composite system that combines valuation, momentum, and macroeconomic factors. The selection universe consists of a series of ETFs that offer exposure to all the major factors and sectors. The portfolios will also move to cash or lower risk assets when technical market conditions deteriorate.
                </Card>
              </GridItem>
            </GridContainer>
            <GridContainer justify="center" className="mt-5 pb-5">
              <GridItem lg="12" md="12" sm="12" className="text-center">
                <Button
                  color="danger"
                  size="md"
                  style={{fontSize: "18px", fontWeight: "bold"}}
                  href="/contact"
                  rel="noopener noreferrer"
                >
                  <i className="fas fa-arrow-circle-right" />
                  Free Assessment
                </Button>
              </GridItem>
            </GridContainer>
            <div>
          </div>
          </div>
          <div>
            <KitSection/>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
