import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// metatags
import MetaTags from 'react-meta-tags';

// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Parallax from "components/Parallax/Parallax.js";

import styles from "assets/jss/material-kit-react/views/landingPage.js";

// Sections for this page
import ProductSection from "./Sections/ProductSection.js";
import TeamSection from "./Sections/TeamSection.js";
import KitSection from "./Sections/KitSection.js";
import GlanceSection from "./Sections/GlanceSection.js";

const dashboardRoutes = [];

const useStyles = makeStyles(styles);

export default function LandingPage(props) {
  const classes = useStyles();
  const { ...rest } = props;
  return (
    <div>
      <MetaTags>
        <title>Investment and Wealth Management | Fairfield and West Hartford Connecticut  | Validea Capital</title>
        <meta name="description" content="Validea Capital is an SEC registered investment advisor that offers wealth management and investment management using proven systematic, factor-based strategies." />
      </MetaTags>
      <Header
        color="transparent"
        routes={dashboardRoutes}
        brand="Validea Capital"
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 200,
          color: "white"
        }}
        {...rest}
      />
      <Parallax filter image={require("assets/img/bg8.jpg")}>
        <div  className={classes.container}>
          <GridContainer>
            <GridItem xs={12} sm={12} md={7}>
              <h1 className={classes.title}>Grow and Preserve Your Wealth Using Proven Systematic Strategies</h1>
              <h4>
              Long-Term Equity Models | Risk-Managed ETF Portfolios | Wealth Management
              </h4>
              <br />

              <Button
                style={{backgroundColor: "#880808", fontSize: "20px", fontWeight: "bold"}}
                size="lg"
                href="/contact"
                rel="noopener noreferrer"
              >
                <i className="fas fa-arrow-circle-right" />
                Free Assessment
              </Button>
            </GridItem>
          </GridContainer>
        </div>
      </Parallax>
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.container}>
          <ProductSection />
        </div>
        <div className={classes.container}>
          <TeamSection />
        </div>
        <div>
          <KitSection />
        </div>
        {/* <div className={classes.container}>
        </div> */}
      </div>
      <Footer />
    </div>
  );
}
