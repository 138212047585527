import React from 'react'
import { Modal, Button } from 'react-bootstrap';

export default function TeamModal(props) {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {props.name}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h6>{props.title}</h6>
          <p>
            {props.description}
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button style={{backgroundColor: "#F34436", borderColor: "#F34436" }} onClick={props.onHide}>Close</Button>
        </Modal.Footer>
      </Modal>
    );
  }
  