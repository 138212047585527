import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { withStyles } from "@material-ui/core/styles";

// bootstrap components
import { Card, Container } from "react-bootstrap";

// @material-ui/icons
// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Parallax from "components/Parallax/Parallax.js";
import TeamModal from "components/TeamModal/TeamModal.js";

// faces
import Jack from "assets/img/faces/JackForehand.jpg";
import John from "assets/img/faces/JohnReeseSketch.jpg";
import Justin from "assets/img/faces/JustinCarbonneau.jpg";
import Andrew from "assets/img/faces/Andrew2.jpg";
import FengDong from "assets/img/faces/FengDong.jpg";
import Dante from "assets/img/faces/Dante.jpg";

// style sheets
import styles from "assets/jss/material-kit-react/views/teamPage.js";
import { MetaTags } from "react-meta-tags";


class TeamPage extends React.Component {
  constructor(props) {
    super(props);
    this.handleShow = this.handleShow.bind(this);
    this.handleClose = this.handleClose.bind(this);

    this.state = {
      show: null
    };
  }
  handleClose(id) {
    this.setState({show: id});
  }

  handleShow(id) {
    this.setState({show: id});
  }

  render() {
  const { classes } = this.props;
  const { ...rest } = this.props;
  return (
    <div>
      <MetaTags>
        <title>Validea Capital Management - Team</title>
      </MetaTags>
      <Header
        color="transparent"
        brand="Validea Capital"
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 200,
          color: "white"
        }}
        {...rest}
      />
      <Parallax small filter image={require("assets/img/bg8.jpg")} />
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div>
          <div className={classes.container}>
            <GridContainer justify="center">
              <GridItem xs={12} sm={12} md={6}>
                <div className={classes.profile}>
                  <div className={classes.name}>
                    <h3 className={classes.title}>Our Team</h3>
                  </div>
                </div>
              </GridItem>
            </GridContainer>
            <GridContainer justify="center" className="pb-5 pr-5 pl-5">
              <GridItem xs={12} sm={12} md={3}>
                <Card onClick={() => this.handleShow('1')} className={classes.card}>
                <img src={Jack} alt="Jack Forehand" style={{width: "100%"}}/>
                  <Container className={classes.container}>
                    <h4><b>Jack Forehand, CFA, CFP&reg;</b></h4>
                    <p>Co-Founder & CIO</p>
                  </Container>
                </Card>
              </GridItem>
              <GridItem xs={12} sm={12} md={3}>
                <Card onClick={() => this.handleShow('2')} className={classes.card}>
                <img src={John} alt="John Reese" style={{width: "100%"}}/>
                  <Container className={classes.container}>
                    <h4><b>John Reese</b></h4>
                    <p>Founder</p>
                  </Container>
                </Card>
              </GridItem>
              <GridItem xs={12} sm={12} md={3}>
                <Card onClick={() => this.handleShow('3')} className={classes.card}>
                <img src={Justin} alt="Justin Carbonneau" style={{width: "100%"}}/>
                  <Container className={classes.container}>
                    <h4><b>Justin Carbonneau</b></h4>
                    <p>Managing Partner</p>
                  </Container>
                </Card>
              </GridItem>
              </GridContainer>
              <GridContainer justify="center" className="pb-5 pr-5 pl-5">
              <GridItem xs={12} sm={12} md={3}>
                <Card onClick={() => this.handleShow('4')} className={classes.card}>
                <img src={Andrew} alt="Andrew Cohen" style={{width: "100%"}}/>
                  <Container className={classes.container}>
                    <h4><b>Andrew Cohen</b></h4>
                    <p> Investment Strategy Advisor</p>
                  </Container>
                </Card>
              </GridItem>
              <GridItem xs={12} sm={12} md={3}>
                <Card onClick={() => this.handleShow('5')} className={classes.card}>
                <img src={FengDong} alt="Feng Dong" style={{width: "100%"}}/>
                  <Container className={classes.container}>
                    <h4><b>Feng Dong, PhD</b></h4>
                    <p>Investment Strategy Advisor</p>
                  </Container>
                </Card>
              </GridItem>
              <GridItem xs={12} sm={12} md={3}>
                <Card onClick={() => this.handleShow('6')} className={classes.card}>
                <img src={Dante} alt="Dante" style={{width: "100%"}}/>
                  <Container className={classes.container}>
                    <h4><b>Dante Lacuadra</b></h4>
                    <p>Software Developer</p>
                  </Container>
                </Card>
              </GridItem>
              </GridContainer>
          </div>
        </div>
      </div>
      <Footer />
      {/* TEAM MODALS */}
      <TeamModal
        show={this.state.show === '1'}
        onHide={this.handleClose}
        name="Jack Forehand, CFA, CFP&reg;"
        title="Co-Founder & CIO"
        description="Jack Forehand is a partner at Validea Capital and is responsible for the firm's overall operations and portfolio management. Working in conjunction with founder John Reese, Jack led the development and optimization of Validea Capital's quantitative investment models. He is also the co-author of The Guru Investor: How to Beat the Market Using History's Best Investment Strategies. Jack graduated from the honors program of the University of Connecticut with a B.A. in Economics and is a CFA charterholder and a CFP&reg; professional. Prior to founding Validea Capital, Jack spent five years developing quantitative models for an Internet research company. Jack co-founded Validea Capital in 2004."
      />
      <TeamModal
        show={this.state.show === '2'}
        onHide={this.handleClose}
        name="John Reese"
        title="Founder"
        description="For over 21 years, John Reese has been conducting extensive research into quantitative investment strategies. The goal of this research was to find strategies that consistently outperform the market. He combined this search with his computer science and artificial intelligence background to develop the multi-factor analysis models that are used by Validea Capital Management today. John is a frequent speaker on quantitative investing and holds two US patents for automated stock analysis. John is the founder of Validea.com and is also the author of The Guru Investor: How to Beat the Market Using History's Best Investment Strategies and is also a regular columnist for Forbes and Canada's Globe and Mail newspaper. He holds his Master's in Business Administration from Harvard Business School and a computer science degree from the Massachusetts Institute of Technology. John co-founded Validea Capital in 2004."
      />
      <TeamModal
        show={this.state.show === '3'}
        onHide={this.handleClose}
        name="Justin Carbonneau"
        title="Managing Partner"
        description="Justin Carbonneau is a partner at Validea Capital and manages the firm's Private Client Group. He also acts as the principal business development officer for the company and is responsible for managing growth efforts and strategic initiatives. Prior to joining Validea Capital, Justin was a controller for a Fortune 500 healthcare company where he was a member of the firm's leadership development program. Justin has a Master's of Business Administration from the University of Connecticut, where he graduated with honors and was inducted into the UConn School of Business Hall of Fame. He was also a member of the Connecticut Air National Guard and a Division I collegiate athlete. Justin joined Validea Capital in 2005."
      />
      <TeamModal
        show={this.state.show === '4'}
        onHide={this.handleClose}
        name="Andrew Cohen"
        title="Investment Strategy Advisor"
        description="Andrew is an award winning Finance Lecturer at Old Dominion University where he won the 'University Distinguished Teacher Award' for 2016-2017 and was selected as a 2017 'Entsminger Fellow'. He is also the Manager of \'The Gregory A. Lumsden Trading Room and Research Lab' at O.D.U. where he is helping faculty utilize Bloomberg Terminals to enhance their classes, and teaching students how utilize Bloomberg Terminals to enhance their education. He is the creator of Validea Capital's Systematic Alpha model. Andrew is a recognized expert in the field of quantitative investment model development. Prior to his academic career, Andrew spent fifteen years as a Wall Street trader. He holds an M.B.A. in Finance/International Business from New York University and a B.S. in Management from the State University of New York at Binghamton."
      />
      <TeamModal
        show={this.state.show === '5'}
        onHide={this.handleClose}
        name="Feng Dong, PhD"
        title="Investment Strategy Advisor"
        description="Dr. Feng Dong is an Assistant Professor of Finance at Siena College. Before that, he was a visiting assistant professor of finance at Old Dominion University. He is the co-developer of Validea Capital's Systematic Alpha model. He received his Ph.D. in Finance, with a minor degree in International Business, from Old Dominion University, and a master’s Degree in finance from the Ohio State University. His research focuses on behavioral finance and asset pricing, and his research has been published in many academic journals including the Journal of European Financial Management, the Journal of Portfolio Management, the Review of Behavioral Finance, and the Journal of Behavioral Finance. Prior to his academic career Feng worked as as an equity trader and financial advisor."
      />
      <TeamModal
        show={this.state.show === '6'}
        onHide={this.handleClose}
        name="Dante Lacuadra"
        title="Software Developer"
        description="Dante is a Harvard College student studying Computer Science with a minor in Mind Brain Behavior (MBB). He is also on the board of Challenge Capital a student-run hedge fund. Dante has built and maintained many of our internal and external tools and sites."
      />
    </div>
  );
  }
}

export default withStyles(styles)(TeamPage);