export const steps = [
    {
        stepNumber: 1,
        name: 'Education',
        content: `Before we ask you to commit your valuable time to speaking with us, we first want to
        make sure you understand what we believe about investing, and how that translates into the portfolio
        we would potentially build for you. To accomplish this, we will send you two things right away once you
        complete the form below:`,
        subSteps: [
            `Our free systematic investing guide, which explains how systematic investing works, the
            types of systematic strategies we run, and the types of investors they make sense for.`,
            `In addition to reading what we believe in, we think it also makes sense for potential
            investors to hear it directly from us. Two partners in our firm, Jack Forehand and Justin
            Carbonneau, host a weekly podcast called Excess Returns where they discuss many issues
            that are relevant to potential clients. We will send you a few of these episodes so you can
            begin the process of meeting us and better understanding what we believe.`
        ]
    },
    {
        stepNumber: 2,
        name: 'Pre-Call',
        content: `Before a potential call to discuss our strategies, we will reach out to you with some
        basic questions that will help determine if we are a good potential fit for you, and if you are a good
        potential fit for us.`
    },
    {
        stepNumber: 3,
        name: 'Phone Consultation',
        content: `If it looks like we could be a good fit for each other, we will send you a link
        to our online calendar program so you can schedule a 30-minute consultation at a time that is
        convenient for you. This consultation will allow us to better understand what you are looking for and
        whether our offerings might meet your needs.`
    },
    {
        stepNumber: 4,
        name: 'Take Time to Think About It',
        content: `We want this to be a long-term relationship. And we want you to
        take as much time as you need to make your decision. So we require that all potential clients take at
        least 24 hours to think about the decision before moving forward. We also require that our firm partners
        discuss each potential client before opening an account to make sure we can offer you a strategy that
        meets your needs and that you are a great long-term fit for us.`
    },
    {
        stepNumber: 5,
        name: 'Beginning our Long-Term Partnership',
        content: `If both of us agree that we are a great fit for each other,
        they next step is to complete our client onboarding process. This process involves signing our client
        agreement, filling out our client questionnaire so we can learn more about you, opening your account at
        our preferred custodian Folio Financial (a division of Goldman Sachs), and you having a one on one
        consultation with one of our firm partners where we will work together to determine the best
        investment strategy for you.
        
        If at any point in this five-step process, you decide you do not want to move forward, just let us know.
        We will never pressure you to move forward if you are not comfortable.
        If you would like to begin the process of finding out more about us and our systematic approach to
        investing, please complete the form below.`
    },
];