import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons
// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Parallax from "components/Parallax/Parallax.js";

// style sheets
import styles from "assets/jss/material-kit-react/views/equityPage.js";

const useStyles = makeStyles(styles);

const NotFound404 = (props) => {
    const classes = useStyles();
    const { ...rest } = props;
    return (
      <div>
        <Header
          color="transparent"
          brand="Validea Capital"
          rightLinks={<HeaderLinks />}
          fixed
          changeColorOnScroll={{
            height: 200,
            color: "white"
          }}
          {...rest}
        />
        <Parallax small filter image={require("assets/img/bg8.jpg")} />
        <div className={classNames(classes.main, classes.mainRaised)}>
          <div>
            <div className={classes.container}>
              <GridContainer justify="center">
                <GridItem lg="12" md="12" sm="12">
                    <h1 className="pt-5 pb-5">404 Resource Not Found...</h1>
                </GridItem>
              </GridContainer>
              <div>
            </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
}

export default NotFound404;