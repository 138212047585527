import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Card from '@material-ui/core/Card';

// @material-ui/icons
// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import Button from "components/CustomButtons/Button.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Parallax from "components/Parallax/Parallax.js";
import GlanceBanner from "components/GlanceBanner/GlanceBanner";

// flat design images
import ProfessorImage from '../../assets/img/undraws/professor.svg';

// style sheets
import styles from "assets/jss/material-kit-react/views/equityPage.js";
import KitSection from "views/LandingPage/Sections/KitSection";
import { MetaTags } from "react-meta-tags";

const useStyles = makeStyles(styles);


export default function SystematicAlphaPage(props) {
  const classes = useStyles();
  const { ...rest } = props;
  return (
    <div>
      <MetaTags>
        <title>Validea Capital Management - Systematic Alpha</title>
        <meta name="description" content="Our Systematic Alpha strategy was developed in conjunction with Andrew Cohen, who is a distinguished lecturer at Old Dominion University, and Professor Feng Dong of Siena University. The strategy is the culmination of their research into the factors that drive stock performance over time."/>
      </MetaTags>
      <Header
        color="transparent"
        brand="Validea Capital"
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 200,
          color: "white"
        }}
        {...rest}
      />
      <Parallax small filter image={require("assets/img/bg8.jpg")} />
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div>
          <div>
            <GlanceBanner 
              title1="Multi-factor model"
              subtitle1="developed via academic research"
              title2="Value-based approach"
              subtitle2="with focus on limiting volatility"
              title3="Consistent industry weightings"
              subtitle3="to help manage risk"
            />
          </div>
          <div className={classes.container}>
            <GridContainer justify="center">
              <GridItem lg="8" md="12" sm="12">
                <h2 style={{marginTop: "70px"}} className="text-center font-weight-bold">Systematic Alpha Strategy</h2>
              </GridItem>
              <GridItem lg="5" md="12" sm="12">
                <Card className={classes.card}>
                  <img alt="" className={classes.image} src={ProfessorImage} />
                </Card>
              </GridItem>
              <GridItem lg="8" md="12" sm="12" className="mt-5">
                <h5 className="text-justify">Our Systematic Alpha strategy was developed in conjunction with Andrew Cohen, who is a distinguished lecturer at Old Dominion University, and Professor Feng Dong of Siena University. The strategy is the culmination of their research into the factors that drive stock performance over time.<br/><br/><br/>
                The strategy of this portfolio is to provide long-term capital appreciation through a concentrated portfolio of all-cap value stocks selected using a systematic investment model. We seek to capitalize on the value premium by buying stocks that display the most attractive rankings based on a variety of cash flow, valuation, sentiment, and safety criteria. Our goal is to select the top ranked stocks to achieve maximum excess return while minimizing overall risk by using stock and industry level diversification. Briefly speaking, we initially set up criteria to eliminate very risky and low ranked stocks. Second, we rank all remaining stocks within each GICS industry group based on a combination of variables, which are filtered from more than 200 different available factors, to measure the relative value of the stocks. Last, we pick the top one from each GICS industry to diversify the idiosyncratic risk. The end result is a portfolio that seeks to capitalize on factor premiums over time while also focusing on managing risk.</h5>
              </GridItem>
            </GridContainer>
            <GridContainer justify="center" className="mt-5 pb-5">
              <GridItem lg="12" md="12" sm="12" className="text-center">
                <Button
                  color="danger"
                  size="md"
                  style={{fontSize: "18px", fontWeight: "bold"}}
                  href="/contact"
                  rel="noopener noreferrer"
                >
                  <i className="fas fa-arrow-circle-right" />
                  Free Assessment
                </Button>
              </GridItem>
            </GridContainer>
            <div>
          </div>
          </div>
          <div>
            <KitSection/>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
