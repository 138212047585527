/*eslint-disable*/
import React from "react";
import DeleteIcon from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";
// react components for routing our app without refresh
import { Link } from "react-router-dom";

// @material-ui/core components
import { makeStyles, withStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Tooltip from "@material-ui/core/Tooltip";

// @material-ui/icons
import { Apps, Language, Book, Phone, Mail } from "@material-ui/icons";

// core components
import CustomDropdown from "components/CustomDropdown/CustomDropdown.js";
import Button from "components/CustomButtons/Button.js";
import LeavingModal from "components/LeavingModal/LeavingModal.js";
import styles from "assets/jss/material-kit-react/components/headerLinksStyle";

// const useStyles = makeStyles(styles);

class HeaderLinks extends React.Component {
  constructor(props) {
    super(props);
    this.handleShow = this.handleShow.bind(this);
    this.handleClose = this.handleClose.bind(this);

    this.state = {
      show: null
    };
  }
  handleClose(id) {
    this.setState({show: id});
  }

  handleShow(id) {
    this.setState({show: id});
  }

  render() {
  const { classes } = this.props;
  return (
    <List className={classes.list}>

      <ListItem className={classes.listItem}>
        <CustomDropdown
          noLiPadding
          buttonText="Offerings"
          buttonProps={{
            className: classes.navLink,
            color: "transparent"
          }}
          dropdownList={[
            <Link to="/equity-portfolio" className={classes.dropdownLink}>
              Equity Portfolios
            </Link>,
            <Link to="/risk-managed-portfolio" className={classes.dropdownLink}>
              Risk Managed Portfolios
            </Link>,
            <Link to="/direct-indexing" className={classes.dropdownLink}>
            Direct Indexing
            </Link>,
             <Link to="/wealth-management" className={classes.dropdownLink}>
              Wealth Management
              </Link>
          ]}
        />
      </ListItem>
      <ListItem className={classes.listItem}>
        <Button
          href="/philosophy"
          color="transparent"
          className={classes.navLink}
        >
        Philosophy & Process
        </Button>
      </ListItem>
      <ListItem className={classes.listItem}>
        <Button
          href="/team"
          color="transparent"
          className={classes.navLink}
        >
          Team
        </Button>
      </ListItem>
      <ListItem className={classes.listItem}>
        <Button
          href="/contact"
          color="transparent"
          className={classes.navLink}
        >
          Free Assessment
        </Button>
      </ListItem>
      <ListItem className={classes.listItem}>
        <Button
          onClick={() => this.handleShow('blogModal')}
          style={{outline:"none"}}
          color="transparent"
          target="_blank"
          className={classes.navLink}
        >
          <Book className={classes.icons} /> Podcast
        </Button>
      </ListItem>
      <ListItem className={classes.listItem}>
        <Button
          color="transparent"
          target="_blank"
          className={classes.navLink}
        >
          <Mail className={classes.icons} /><a href="mailto:info@valideacapital.com" style={{color: "inherit"}}>info@valideacapital.com</a>
        </Button>
      </ListItem>
      <ListItem className={classes.listItem}>
        <Button
          color="transparent"
          target="_blank"
          className={classes.navLink}
        >
          <Phone className={classes.icons} /><a href="tel:+1-800-730-3457" style={{color: "inherit"}}>+1-800-730-3457</a>
        </Button>
      </ListItem>
      {/* Leaving Modal */}
      <LeavingModal 
        link="https://www.youtube.com/excessreturns"
        show={this.state.show == 'blogModal'}
        onHide={this.handleClose}
        shortName="Podcast"
        longName="Excess Returns Podcast"
      />
    </List>
  );
  }
}

export default withStyles(styles)(HeaderLinks);