import React from "react";
import {withRouter, Redirect} from "react-router-dom";

// nodejs library that concatenates classes
import classNames from "classnames";


// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import Button from "components/CustomButtons/Button.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Parallax from "components/Parallax/Parallax.js";


// styles
import styles from "assets/jss/material-kit-react/views/profilePage.js";
import { withStyles } from '@material-ui/styles';
import { PieChart } from 'react-minimal-pie-chart';
 
// bootstrap
import { Card } from "react-bootstrap";

//pie chart data
import { portfolioPercents } from "./portfolioPercents";
import descriptions from "./data/descriptions";

class RoboAdvisorResultPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }

  render() {
  if (!this.props.location.state) {
    return <Redirect to="/" />
  }

  const { ...rest } = this.props;
  const { classes } = this.props;
  const allocationDict = portfolioPercents(this.props.location.state.data.roboPortfolioName);
  const folioLink = "https://folioclient.com/servlets/ProcessAction/signup?firm=1801439852997513230&extscore=" + (this.props.location.state.data.folioPort).toString();
  return (
    <div>
      <Header
        color="transparent"
        brand="Validea Capital"
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 200,
          color: "white"
        }}
        {...rest}
      />
      <Parallax small filter image={require("assets/img/bg8.jpg")} />
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div>
          <div className={classes.container}>
            <GridContainer justify="center">
              <GridItem lg="4" md="12" sm="12">
                  <div className="mt-2 mb-2 text-center">
                    <Button target="_blank" href={folioLink} color="success" size="md">Open an account</Button>
                    &nbsp;&nbsp;
                    <Button href="/robo-advisor-questionaire" color="danger" size="md">Start over</Button>
                  </div>
              </GridItem>
            </GridContainer>
            <GridContainer justify="center">
                <GridItem className="mt-2" lg="4" md="12" sm="12">
                  <Card className="rounded-0 shadow">
                    <h3 className="text-center">Your risk score: <br/> {this.props.location.state.data.riskNumber}/100</h3>
                  </Card>
                </GridItem>
                <GridItem className="mt-2" lg="4"  md="12" sm="12">
                  <Card className="rounded-0 shadow">
                    <h3 className="text-center">Your portfolio selection: <br/> <span className="font-weight-bold">{(this.props.location.state.data.roboPortfolioName)}</span></h3>
                  </Card>
                </GridItem>
            </GridContainer>
            <GridContainer className="mt-4" justify="center">
              <GridItem lg="12">
                <Card className="p-3 rounded-0 shadow">
                    <h4 className="text-center">Account Opening: <br/></h4>
                    <h5 className="text-justify">
                      To complete the account opening process with our preferred custodian Folio Investments and invest in this portfolio, please click "Open Account". If you do not think this portfolio is right for you, click "Start Over" and update your questionnaire. If you have any questions, we are here to help. You can reach us at 800-730-3457 or info@valideacapital.com.
                    </h5>
                </Card>
              </GridItem>
            </GridContainer>
            <GridContainer className="mt-4" justify="center">
              <GridItem lg="12">
                <Card className="p-3 rounded-0 shadow">
                    <h4 className="text-center">Portfolio Description: <br/></h4>
                    <h5 className="text-justify">
                      {descriptions(this.props.location.state.data.roboPortfolioName)}
                    </h5>
                </Card>
              </GridItem>
            </GridContainer>
            <GridContainer className="mt-0 pb-5 " justify="center">
              <GridItem lg="8" md="12" sm="12">
                <h3 className="text-center mt-5">Portfolio Allocation</h3>
                <PieChart
                  style={{
                    marginLeft: "auto",
                    marginRight: "auto",
                    maxHeight: "500px",
                    fontFamily:
                      '"Nunito Sans", -apple-system, Helvetica, Arial, sans-serif',
                    fontSize: '3px',
                  }}
                  animate
                  radius={PieChart.defaultProps.radius - 20}
                  segmentsStyle={{ transition: 'stroke .3s', cursor: 'pointer' }}
                  label={({ dataEntry }) => (dataEntry.value).toString() + "% " + (dataEntry.title.toString())}
                  labelPosition={110}
                  labelStyle={{
                    fill: 'black',
                    opacity: 0.75,
                    pointerEvents: 'none'
                  }}
                  data={allocationDict}
                />
              </GridItem>
            </GridContainer>
            <GridContainer justify="center">
                <GridItem lg="4" md="12" sm="12" className="text-center">
                <Button
                  color="danger"
                  className="mb-5"
                  size="md"
                  href="/contact"
                  rel="noopener noreferrer"
                >
                  <i className="fas fa-arrow-circle-right" />
                  Free Assessment
                </Button>
                </GridItem>
            </GridContainer>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
  }
}

export default withRouter(withStyles(styles)(RoboAdvisorResultPage));
