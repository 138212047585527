import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons
import Computer from '@material-ui/icons/Computer';
import ShowChart from '@material-ui/icons/ShowChart';
import VerifiedUser from "@material-ui/icons/VerifiedUser";

import List from '@material-ui/icons/List';

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import InfoArea from "components/InfoArea/InfoArea.js";

// styles
import styles from "assets/jss/material-kit-react/views/landingPageSections/productStyle.js";


import Slide from "@material-ui/core/Slide";

const useStyles = makeStyles(styles);

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

Transition.displayName = "Transition";

export default function ProductSection() {
  const classes = useStyles();
  return (
    <div className={classes.section}>
      <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={8}>
          <h2 className={classes.title}>How Can We Help You?</h2>
          <h5 className={classes.description}>
            Investment Solutions for Long-Term Investors
          </h5>
        </GridItem>
      </GridContainer>
      <div>
        <GridContainer>
          <GridItem xs={12} sm={12} md={3} className="text-center">
            <InfoArea
              title="Enhance Long-Term Equity Returns"
              description="We build portfolios using factors that work over the long-term and blend strategies to smooth returns over time."
              icon={ShowChart}
              iconColor="valideaBlue"
              vertical
              href="equity-portfolio"
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={3} className="text-center">
            <InfoArea
              title="Manage Risk and Limit Losses"
              description="We have developed multi-asset models that go beyond traditional stocks and bonds using highly liquid ETFs."
              icon={VerifiedUser}
              iconColor="valideaBlue"
              vertical
              href="risk-managed-portfolio"
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={3} className="text-center">
            <InfoArea
              title="Build Tax Efficient Passive Portfolios"
              description="Our direct indexing strategies track popular market indexes more tax efficiently."
              icon={List}
              iconColor="valideaBlue"
              vertical
              href="direct-indexing"
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={3} className="text-center">
            <InfoArea
              title="Develop a Long-Term Financial Plan"
              description="We will work with you to create and implement a plan to achieve your financial goals and build and preserve wealth."
              icon={Computer}
              iconColor="valideaBlue"
              vertical
              href="wealth-management"
            />
          </GridItem>
        </GridContainer>
      </div>
    </div>
  );
}
