import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";

import styles from "assets/jss/material-kit-react/views/landingPageSections/teamStyle.js";

import Jack from "assets/img/faces/JackForehand.jpg"
import John from "assets/img/faces/JohnReeseSketch.jpg"
import Justin from "assets/img/faces/JustinCarbonneau.jpg"

const useStyles = makeStyles(styles);

export default function TeamSection() {
  const classes = useStyles();
  const imageClasses = classNames(
    classes.imgRaised,
    classes.imgRoundedCircle,
    classes.imgFluid
  );
  return (
    <div className={classes.section}>
      <h2 className={classes.title}>Who We Are</h2>
      <div>
        <GridContainer>
        <GridItem xs={12} sm={12} md={4}>
            <Card className={classes.card}>
              <GridItem xs={12} sm={12} md={6} className={classes.itemGrid}>
                <img src={Jack} alt="..." className={imageClasses} />
              </GridItem>
              <h4 className={classes.cardTitle}>
                Jack Forehand, CFA, CFP&reg;
                <br />
                <small className={classes.smallTitle}>Co-Founder & CIO</small>
              </h4>
              <CardBody>
                <p className={classes.description}>
                Jack Forehand is a partner at Validea Capital and is responsible for the firm's overall operations and portfolio management. Working in conjunction with founder John Reese, Jack led the development and optimization of Validea Capital's quantitative investment models.
                </p>
              </CardBody>
              <CardFooter style={{marginTop: "49px"}} className={classes.justifyCenter}>
                <Button
                  justIcon
                  target="_blank"
                  href="https://twitter.com/practicalquant?ref_src=twsrc%5Egoogle%7Ctwcamp%5Eserp%7Ctwgr%5Eauthor"
                  color="transparent"
                  className={classes.margin5}
                >
                  <i className={classes.socials + " fab fa-twitter"} />
                </Button>
                <Button
                  justIcon
                  target="_blank"
                  href="https://www.linkedin.com/in/jack-forehand-8015094/"
                  color="transparent"
                  className={classes.margin5}
                >
                  <i className={classes.socials + " fab fa-linkedin"} />
                </Button>
              </CardFooter>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <Card className={classes.card}>
              <GridItem xs={12} sm={12} md={6} className={classes.itemGrid}>
                <img src={John} alt="..." className={imageClasses} />
              </GridItem>
              <h4 className={classes.cardTitle}>
                John Reese
                <br />
                <small className={classes.smallTitle}>Founder & CEO</small>
              </h4>
              <CardBody>
                <p className={classes.description}>
                For over 21 years, John Reese has been conducting extensive research into quantitative investment strategies. The goal of this research was to find strategies that consistently outperform the market. He combined this search with his computer science and artificial intelligence background to develop the multi-factor analysis models that are used by Validea Capital Management today.
                </p>
              </CardBody>
              <CardFooter className={classes.justifyCenter}>
                <Button
                  justIcon
                  target="_blank"
                  href="https://www.linkedin.com/in/johnreese2/"
                  color="transparent"
                  className={classes.margin5}
                >
                  <i className={classes.socials + " fab fa-linkedin"} />
                </Button>
              </CardFooter>
            </Card>
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <Card className={classes.card}>
              <GridItem xs={12} sm={12} md={6} className={classes.itemGrid}>
                <img src={Justin} alt="..." className={imageClasses} />
              </GridItem>
              <h4 className={classes.cardTitle}>
                Justin Carbonneau
                <br />
                <small className={classes.smallTitle}>Managing Partner</small>
              </h4>
              <CardBody>
                <p className={classes.description}>
                Justin Carbonneau is a partner at Validea Capital and manages the firm's Private Client Group. He also acts as the principal business development officer for the company and is responsible for managing growth efforts and strategic initiatives.
                </p>
              </CardBody>
              <CardFooter style={{marginTop: "72px"}} className={classes.justifyCenter}>
              <Button
                  justIcon
                  color="transparent"
                  target="_blank"
                  href="https://twitter.com/JJCarbonneau?ref_src=twsrc%5Egoogle%7Ctwcamp%5Eserp%7Ctwgr%5Eauthor"
                  className={classes.margin5}
                >
                  <i className={classes.socials + " fab fa-twitter"} />
                </Button>
                <Button
                  justIcon
                  target="_blank"
                  href="https://www.linkedin.com/in/jcarbonneau/"
                  color="transparent"
                  className={classes.margin5}
                >
                  <i className={classes.socials + " fab fa-linkedin"} />
                </Button>
              </CardFooter>
            </Card>
          </GridItem>
        </GridContainer>
        <div className="d-flex justify-content-center">
            <Button
              className="float-none mt-2"
              style={{backgroundColor: "gray"}}
              size="sm"
              href="/team"
              rel="noopener noreferrer"
            >
              <i className="fas fa-arrow-circle-right" />
              See more
          </Button>
          </div>
      </div>
    </div>
  );
}
