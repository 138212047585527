export function ValidateForm(index, value) {
    switch(index) {
        case 0:
            if (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(value))
            {
                break;
            } else {
                return "Please enter a valid email address"
            }
        case 1: 
            if (18 > value) {
                return "You must be 18 or older to continue"
            }
            else if (value > 100) {
                return "You must enter a valid age"
            }
            else {
                break;
            }
        case 2: 
        case 3:
        case 4:
            if (value <= 0) {
                return "You must enter a number"
            }
            else if (value >= 10000000000) {
                return "Do you really make that much?"
            }
            else {
                break;
            }
        case 5:
            if (value !== 0 && value !== 1 && value !== 2) {
                
                
                return "Please select an option"
            }
            else {
                break;
            }
        case 6:
            if (value !== 0 && value !== 1) {
                return "Please select an option"
            }
            else {
                break;
            }
        case 7:
            if (value !== 0 && value !== 1 && value !== 2 && value !== 3) {
                return "Please select an option"
            }
            else {
                break;
            }
        case 8:
            if (value !== 0 && value !== 1 && value !== 2) {
                return "Please select an option"
            }
            else {
                break;
            }
        case 9:
            if (value !== 0 && value !== 1 && value !== 2 && value !== 3) {
                return "Please select an option"
            }
            else {
                break;
            }
        case 10:
            if (value !== 0 && value !== 1 && value !== 2 && value !== 3) {
                return "Please select an option"
            }
            else {
                break;
            }
        default:
            break; 
            
    }
}