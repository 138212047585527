import React from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { Router, Route, Switch, Redirect } from "react-router-dom";
import ReactGA from 'react-ga';

import "assets/scss/material-kit-react.scss?v=1.9.0";
import "assets/css/personal.css";

// pages for this product
import LandingPage from "views/LandingPage/LandingPage.js";
import PhilosophyPage from "views/PhilosophyPage/PhilosophyPage.js";
import WealthPage from "views/WealthPage/WealthPage.js";
import RoboAdvisorQuestionairePage from "views/RoboAdvisorQuestionairePage/RoboAdvisorQuestionairePage";
import RoboAdvisorResultPage from "views/RoboAdvisorQuestionairePage/RoboAdvisorResultPage";
import TeamPage from "views/TeamPage/TeamPage";
import EquityPage from "views/EquityPage/EquityPage";
import RiskManagedPage from "views/RiskManagedPage/RiskManagedPage";
import ContactPage from "views/ContactPage/ContactPage";
import RoboAdvisorPage from "views/RoboAdvisorPage/RoboAdvisorPage";
import DirectIndexingPage from "views/DirectIndexingPage/DirectIndexingPage";
import SystematicAlphaPage from "views/SystematicAlphaPage/SystematicAlphaPage";
import DisclaimerPage from "views/DisclaimerPage/DisclaimerPage";
import NotFound404 from "views/NotFound404/NotFound404";
import UnsubscribePage from "views/UnsubscribePage/UnsubscribePage";

var hist = createBrowserHistory();
// GOOGLE ANALYTICS
const trackingId = "UA-120596-2"; // Replace with your Google Analytics tracking ID
ReactGA.initialize(trackingId);

hist.listen(location => {
  ReactGA.set({ page: location.pathname }); // Update the user's current page
  ReactGA.pageview(location.pathname); // Record a pageview for the given page
});

ReactDOM.render(
  <Router history={hist}>
    <Switch>
      <Route exact path="/" component={LandingPage} />
      <Route exact path="/equity-portfolios">
        {<Redirect to="/equity-portfolio" />}
      </Route>
      <Route exact path="/risk-managed-portfolios">
        {<Redirect to="/risk-managed-portfolio" />}
      </Route>
      <Route exact path="/risk-analyzer">
        {<Redirect to="/robo-advisor-questionaire" />}
      </Route>   
      <Route exact path="/philosophy" component={PhilosophyPage} />
      <Route exact path="/wealth-management" component={WealthPage} />
      <Route exact path="/about-us">
        {<Redirect to="/team" />}
      </Route>
      <Route exact path="/team" component={TeamPage} />
      <Route exact path="/equity-portfolio" component={EquityPage} />
      <Route exact path="/risk-managed-portfolio" component={RiskManagedPage} />
      <Route exact path="/robo-advisor-questionaire" component={RoboAdvisorQuestionairePage} />
      <Route exact path="/robo-advisor" component={RoboAdvisorPage} />
      <Route exact path="/robo-advisor-result" component={RoboAdvisorResultPage} />
      <Route exact path="/direct-indexing" component={DirectIndexingPage} />
      <Route exact path="/systematic-alpha" component={SystematicAlphaPage} />
      <Route exact path="/disclaimer" component={DisclaimerPage} />
      <Route exact path="/contact" component={ContactPage} />
      <Route exact path="/unsubscribe/:email" component={UnsubscribePage} />
      <Route path="*" component={NotFound404} status={404} />
    </Switch>
  </Router>,
  document.getElementById("root")
);
