import React from 'react'
import { Modal, Button } from 'react-bootstrap';

export default function LeavingModal(props) {
    return (
      <Modal
        style={{zIndex: 2000}}
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {props.shortName}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            You are <span className="font-weight-bold">leaving</span> the website of <span className="font-italic">Validea Capital Management</span> and <span className="font-weight-bold">entering</span> YouTube, which hosts the <span className="font-weight-bold">Excess Returns Podcast</span>. The information in the podcast is not investment advice and should not be considered representative of the views of Validea Capital.
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button style={{backgroundColor: "#F34436", borderColor: "#F34436"}} href={props.link}>Continue</Button>
          <Button style={{backgroundColor: "gray", borderColor: "gray" }} onClick={props.onHide}>Close</Button>
        </Modal.Footer>
      </Modal>
    );
  }
  