import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import Card from '@material-ui/core/Card';

// @material-ui/icons
// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import Button from "components/CustomButtons/Button.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Parallax from "components/Parallax/Parallax.js";
import GlanceBanner from "components/GlanceBanner/GlanceBanner";

// flat design images
import ProgressImage from '../../assets/img/undraws/progress.svg';

// style sheets
import styles from "assets/jss/material-kit-react/views/equityPage.js";
import KitSection from "views/LandingPage/Sections/KitSection";
import { MetaTags } from "react-meta-tags";

const useStyles = makeStyles(styles);


export default function DirectIndexingPage(props) {
  const classes = useStyles();
  const { ...rest } = props;
  return (
    <div>
      <MetaTags>
        <title>Validea Capital Management - Direct Indexing</title>
        <meta name="description" content="Track the returns of popular market indexes in a more tax efficient manner than traditional fund-based approaches."/>
      </MetaTags>
      <Header
        color="transparent"
        brand="Validea Capital"
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 200,
          color: "white"
        }}
        {...rest}
      />
      <Parallax small filter image={require("assets/img/bg8.jpg")} />
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div>          
          <div>
            <GlanceBanner 
              title1="Follow"
              subtitle1="popular indexes"
              title2="Boost"
              subtitle2="tax alpha using tax loss harvesting"
              title3="Customize"
              subtitle3="portfolio based on ESG and using single stock exclusions"
            />
          </div>
          <div className={classes.container}>
            <GridContainer justify="center">
              <GridItem lg="12" md="12" sm="12">
                <h2 style={{marginTop: "70px"}} className="text-center font-weight-bold">Direct Indexing</h2>
              </GridItem>
              <GridItem lg="5" md="12" sm="12">
                <Card className={classes.card}>
                  <img alt="" className={classes.image} src={ProgressImage} />
                </Card>
              </GridItem>
              <GridItem lg="8" md="12" sm="12">
                <h5 className="pt-5 pb-5 text-justify">
                Our Direct Indexing products allow investors to track the returns of popular market indexes in a more tax efficient manner than traditional fund-based approaches. Clients can specify the index they would like to track and we will build a portfolio using the individual stocks in that index. Direct Indexing allows investors to closely track the performance of an index, while gaining the advantages of tax loss harvesting over time. It also allows for personalized customization of the index using factors like ESG or individual security exclusion.
                </h5>
              </GridItem>
            </GridContainer>
            <GridContainer justify="center" className="mt-0 pb-5">
              <GridItem lg="12" md="12" sm="12" className="text-center">
                <Button
                  color="danger"
                  size="md"
                  href="/contact"
                  rel="noopener noreferrer"
                  style={{fontSize: "18px", fontWeight: "bold"}}
                >
                  <i className="fas fa-arrow-circle-right" />
                  Free Assessment
                </Button>
              </GridItem>
            </GridContainer>
          </div>
          <div>
            <KitSection/>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
