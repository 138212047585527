import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons
// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Parallax from "components/Parallax/Parallax.js";

// flat design images

// style sheets
import styles from "assets/jss/material-kit-react/views/equityPage.js";

const useStyles = makeStyles(styles);


export default function DisclaimerPage(props) {
  const classes = useStyles();
  const { ...rest } = props;
  return (
    <div>
      <Header
        color="transparent"
        brand="Validea Capital"
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 200,
          color: "white"
        }}
        {...rest}
      />
      <Parallax small filter image={require("assets/img/bg8.jpg")} />
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div>
          <div className={classes.container}>
            <GridContainer justify="center">
              <GridItem lg="12" md="12" sm="12">
                <h2 className="text-center font-weight-bold">Disclaimer</h2>
              </GridItem>
              <GridItem lg="8" md="12" sm="12">
                <h5 className="pt-5 pb-5 text-justify">
                Validea Capital Management, LLC. is a registered investment advisor under the Investment Advisors' Act of 1940 currently registered in the states of Connecticut, California, Virginia and Colorado. The names of individual investors appearing on this website are for identification purposes of his methodology only, as derived by Validea Capital from published sources, and are not intended to suggest or imply any affiliation with or endorsement or even agreement with this document personally by such gurus, or any knowledge or approval by such persons of the content of this website. All trademarks, service marks and tradenames appearing on this website are the property of their respective owners, and are likewise used for identification purposes only. Investing in the stock market involves risk, including the risk of principal loss. Information on this website is in no way intended as personalized investment advice and should not be interpreted as such.
                Due to the concentrated nature of Validea Capital's strategies, the firm's portfolios tend to exhibit more volatility than the overall market or comparable benchmark. As with any investment strategy, there is potential for profit as well as the possibility of loss and investors may incur a loss despite a past history of gains. Past performance does not guarantee future results. Results will vary with economic and market conditions.                </h5>
              </GridItem>
            </GridContainer>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
