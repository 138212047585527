import React from "react";
import axios from "axios";

// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { withStyles } from "@material-ui/core/styles";

// @material-ui/icons

// react boostrap
import { Form } from 'react-bootstrap';

// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import Button from "components/CustomButtons/Button.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Parallax from "components/Parallax/Parallax.js";
import CustomInput from "components/CustomInput/CustomInput.js";
import SnackbarContent from 'components/Snackbar/SnackbarContent';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

// flat design images
import SurveyImage from '../../assets/img/undraws/survey.svg';
import PhoneCallImage from '../../assets/img/undraws/phoneCall.svg';
import ThinkImage from '../../assets/img/undraws/think.svg';
import BeginImage from '../../assets/img/undraws/begin.svg';

// style sheets
import styles from "assets/jss/material-kit-react/views/contactPage.js";

// links
import APIUrl from "../../utils/APIUrl";
import { MetaTags } from "react-meta-tags";
import { steps } from "./steps.js";
import { Box } from "@material-ui/core";
import KitSection from "views/LandingPage/Sections/KitSection";

class ContactPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: null,
      email: null,
      phone: null,
      interest: null,
      portfolioSize: null,
      submitted: false,
      submitErr: false,
      currentStepContent: null,
      activeStep: null,
    }
  }

  updateName = (e) => {
    this.setState({ name: e.target.value });
  }

  updateEmail = (e) => {
    this.setState({ email: e.target.value });
  }

  updatePhone = (e) => {
    this.setState({ phone: e.target.value });
  }

  updateInterest = (e) => {
    this.setState({ interest: e.target.value });
  }

  updatePortfolioSize = (e) => {
    this.setState({ portfolioSize: e.target.value });
  }

  handleSubmit = () => {
    var postEmail = null;
    if (this.state.email) {
      postEmail = this.state.email
    }
    else {
      if (this.props.location.state) {
        postEmail = this.props.location.state.preEmail
      }
    }
    if (
        !this.state.name || !postEmail ||
        !this.state.phone || (this.state.interest === 'Interest') ||
        (this.state.portfolioSize === 'Portfolio Size') ||
        !this.state.portfolioSize || !this.state.interest
       ) 
      {
        this.setState({submitErr: true});
        
    }
    else {
      var url = APIUrl() + 'api/contactSubmit';
      axios.post(url, {
        name: this.state.name,
        email: postEmail,
        phone: this.state.phone,
        interest: this.state.interest,
        portfolioSize: this.state.portfolioSize
      })
      .then(response => {
        
        this.setState({submitted: true});
      })
      .catch(error => {
        
      });
    }
  }

  componentDidMount() {
    if (!this.state.currentStepContent) {
      this.setState({ currentStepContent: steps[0].content });
    }
    if (!this.state.activeStep) {
      this.setState({ activeStep: 1 });
    }
  }

  handleSidebarItemClick(newItem) {
    this.setState({
      activeStep: newItem.stepNumber,
      currentStepContent: newItem.content,
    });
  }

  render() {
  const { classes } = this.props;
  const { ...rest } = this.props;
  var preEmail = null;
  if (this.props.location.state) {
    preEmail = this.props.location.state.preEmail;
  }
  var submitted = null;
  var submitErr = null;
  if (this.state.submitted) {
    submitted = <SnackbarContent icon="info_outline" close message="Your message has been sent" color="success" />
  }
  else if (this.state.submitErr) {
    submitErr = <SnackbarContent icon="info_outline" close message="Please fill out/select all fields" color="danger" />
  }
  var fromInvestmentKit = null;
  if (this.props.location.state) {
    fromInvestmentKit = <SnackbarContent icon="info_outline" close message="Thank you for downloading our investment kit. It has been emailed to you. If you would like to speak to one of our representatives to find out more about our offerings, please complete the form below." color="success" />
  }
  return (
    <div>
      <MetaTags>
        <title>Free Assessment | Validea Capital Management</title>
        <meta name="description" content="Find out more about Validea Capital Management and get a free consultation"/>
      </MetaTags>
      <Header
        color="transparent"
        brand="Validea Capital"
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 200,
          color: "white"
        }}
        {...rest}
      />
      <Parallax small filter image={require("assets/img/bg8.jpg")} />
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div>
          {fromInvestmentKit}
          {submitted}
          {submitErr}
        </div>
        <div>
          <div className={classes.container}>
            <GridContainer justify="center">
              <GridItem lg="12" md="12" sm="12">
                <h2 className="text-center font-weight-bold">Work With Us - Free Assessment</h2>
                <h5>
                  <span className="font-weight-bold">Goal</span>: To help educate you on systematic investment strategies and 
                  their positives and negatives, and to help you work towards an informed decision on placing an investment with us.
                </h5>
                <h5>
                <span className="font-weight-bold">Process</span>: We have a straight forward five-step process we’ll go through, keeping this streamlined and
                  efficient for you. We want to respect your time and help you get the most out of our experience and
                  expertise.
                </h5>
              </GridItem>
            </GridContainer>
            <GridContainer justify="center">
              <GridItem lg="4" md="3" sm="12">
                {steps.map((step, stepIndex) => {
                  return (
                    <Box onClick={() => this.handleSidebarItemClick(step)} key={stepIndex} className={this.state.activeStep === step.stepNumber ? classes.activeStepSidebarItem : classes.stepSidebarItem} display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
                      <span>Step #{step.stepNumber}: {step.name}</span>
                      <ArrowRightIcon className={classes.rightArrow}/>
                      <ArrowDropDownIcon className={classes.downArrow} />
                    </Box>
                  )
                })}
              </GridItem>
              <GridItem lg="8" md="9" sm="12">
                <Box className={classes.stepItemContent}>
                  {this.state.currentStepContent}
                  {this.state.activeStep === 1 && ( 
                    steps[0].subSteps.map((subStep, subStepIndex) => {
                      return (
                      <h5>{subStepIndex + 1}. {subStep}</h5>
                      )
                    })
                  )}
                  {this.state.activeStep === 1 && (
                    <KitSection onContactPage />
                  )}
                  {this.state.activeStep === 2 && (
                    <img src={SurveyImage} className={classes.contactSubImage} alt="" />
                  )}
                  {this.state.activeStep === 3 && (
                    <img src={PhoneCallImage} className={classes.contactSubImage} alt="" />
                  )}
                  {this.state.activeStep === 4 && (
                    <img src={ThinkImage} className={classes.contactSubImage} alt="" />
                  )}
                  {this.state.activeStep === 5 && (
                    <img src={BeginImage} className={classes.contactSubImageSmall} alt="" />
                  )}
                </Box>
              </GridItem>
            </GridContainer>
            {!submitted ?
            <div>
            <GridContainer justify="center" className="mt-5">
              <GridItem sm="12" lg="5" md="12">
                <CustomInput
                    required
                    onChange={this.updateName}
                    labelText="Your Name"
                    id="name"
                    formControlProps={{
                      fullWidth: true
                    }}
                  />
              </GridItem>
              <GridItem sm="12" lg="5" md="12">
                <CustomInput
                    required
                    onChange={this.updateEmail}
                    defaultValue={preEmail}
                    labelText="Your Email"
                    id="email"
                    formControlProps={{
                      fullWidth: true
                    }}
                  />
              </GridItem>
            </GridContainer>
            <GridContainer justify="center">
              <GridItem sm="12" lg="5" md="12">
                <CustomInput
                    required
                    onChange={this.updatePhone}
                    labelText="Your Phone Number"
                    id="phone"
                    formControlProps={{
                      fullWidth: true
                    }}
                  />
              </GridItem>
              <GridItem sm="12" lg="3" md="12">
                <Form.Group style={{marginTop: "28px"}}>
                  <Form.Control id="interest" onChange={this.updateInterest} size="sm" as="select">
                    <option className="font-weight-light">Interest</option>
                    <hr/>
                    <option>General Questions</option>
                    <option>Equity Portfolios</option>
                    <option>Risk Managed Portfolios</option>
                    <option>Wealth Management</option>
                    <option>Direct Indexing</option>
                    <option>Robo Advisor</option>
                  </Form.Control>
                </Form.Group>
              </GridItem>
              <GridItem sm="12" lg="2" md="12">
                <Form.Group style={{marginTop: "28px"}}>
                  <Form.Control id="portfolioSize" onChange={this.updatePortfolioSize} size="sm" as="select">
                    <option className="font-weight-light">Portfolio Size</option>
                    <hr/>
                    <option>$100,000 - $250,000</option>
                    <option>$250,000 - $500,000</option>
                    <option>$500,000 - $750,000</option>
                    <option>$750,000 - $1,000,000</option>
                    <option>$1,000,000 - $5,000,000</option>
                    <option>$5,000,000 and above</option>
                  </Form.Control>
                </Form.Group>
              </GridItem>         
              <GridItem lg="10" className="mt-5 mb-5">
                <Button
                onClick={() => this.handleSubmit()}
                style={{backgroundColor: "#A0A9DA", fontSize: "14px", outline: "none"}}
                size="md"
              >
                <i className="fas fa-arrow-circle-right" />
                Submit
              </Button>
              </GridItem>
            </GridContainer>
            </div> 
            :
                <GridContainer justify="center">
                  <GridItem lg="6" md="12" sm="12">
                    <h5 className="text-center pt-5 pb-5">
                      Thank you! One of our advisors will be in touch with you shortly.
                    </h5>
                  </GridItem>
                </GridContainer>
            }
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
  }
}

export default withStyles(styles)(ContactPage);