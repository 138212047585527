export default function questions(pos, portfolioSize) {
    switch(pos) {
        case 0:
            return {
                index: 0,
                name: "What is your email address",
                type: "email",
                placeholder: "email..."
            }
        case 1:
            return {
                index: 1,
                name: "What is your current age?",
                type: "number",
                placeholder: "e.g. 36"
            }
        case 2:
            return {
                index: 2,
                name: "What is you annual pre-tax household income",
                type: "number",
                placeholder: "e.g. 25000"
        }
        case 3:
            return {
                index: 3,
                name: "What is the total value of your cash and liquid investments (stocks, bonds etc)?",
                type: "number",
                placeholder: "e.g. 95000"
        }
        case 4:
            return {
                index: 4,
                name: "What is the total amount you plan to invest with us?",
                type: "number",
                placeholder: "e.g. 100000"
        }
        case 5:
            return {
                index: 5,
                name: "What is the primary goal of your investment with us?",
                type: "dropdown",
                options: [
                    "Long-Term Growth", "Generating Capital", "Preserving Capital"
                ]
        }
        case 6:
            return {
                index: 6,
                name: "Will you be investing taxable or non-taxable money with us?",
                type: "dropdown",
                options: [
                    "Taxable", "Non-Taxable (IRA)"
                ]
        }
        case 7:
            return {
                index: 7,
                name: "How long can you invest before you will need to withdraw all or most of your money?",
                type: "dropdown",
                options: [
                    "1-2 years", "3-5 years", "6-10 years", "10+ years"
                ]
        }
        case 8:
            return {
                index: 8,
                name: "What is most important to you?",
                type: "dropdown",
                options: [
                    "Maximizing gains", "Minimizing Losses", "A blend of both"
                ]
        }
        case 9:
            return {
                index: 9,
                name: "If your investment with us was to decline 10% over the next month, what would you do?",
                type: "dropdown",
                options: [
                    "I would sell all my investments", "I would sell some of my investments", "I would do nothing", "I would buy more"
                ]
        }
        case 10:
            var formatter = new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: 'USD',
            });
            var formattedPortfolioSize,
                formatted10Percent,
                formatted20Percent,
                formatted50Percent;
            try {
                formattedPortfolioSize = formatter.format(parseInt(portfolioSize));
                formatted10Percent = formatter.format(parseInt(portfolioSize * .1));
                formatted20Percent = formatter.format(parseInt(portfolioSize * .2));
                formatted50Percent = formatter.format(parseInt(portfolioSize * .5));
            }
            catch (error) {

            }
            return {
                index: 10,
                name: "What is the maximum loss you would sustain on your " + formattedPortfolioSize + " investment before you would sell or reduce your risk exposure?",
                type: "dropdown",
                options: [
                    "Less than " + formatted10Percent, formatted20Percent, formatted50Percent, "No maximum"
                ]
        }
        default: 
            return 1;
    }
       
}