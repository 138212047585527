import React from "react";
import {withRouter} from "react-router-dom";

// nodejs library that concatenates classes
// @material-ui/core components
import { withStyles } from "@material-ui/core/styles";
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

// react bootstrap components
import { Form } from "react-bootstrap";
import Button from "../../../components/CustomButtons/Button";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import SnackbarContent from 'components/Snackbar/SnackbarContent';

// api connection
import axios from "axios";

import styles from "assets/jss/material-kit-react/views/landingPageSections/kitStyle.js";

// links
import APIUrl from '../../../utils/APIUrl';

class KitSection extends React.Component {
  constructor(props){
      super(props);
      this.state = {
          email: null,
          errBlock: null,
          contactPageSuccess: false,
      }
  }

  sendEmail(e) {
      e.preventDefault();
      
      

      // validate an email
      if (!this.state.email || (!(/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(this.state.email)))) {
        this.setState({ errBlock: true });
        return 1;
      }

      // backend route to email
      const url = APIUrl() + 'api/systematicInvestingRequest';
      axios.post(url, {
        email: this.state.email
      });

      // redirect to contact form w/ email prefilled
      if (!this.props.onContactPage) {
        this.props.history.push({
            pathname:"/contact",
            state:{
                preEmail: this.state.email
             }
        });
      }
      else {
        this.setState({ contactPageSuccess: true });
      }
  }

  render() {
    const { classes, onContactPage } = this.props;
    var submitErr = null;
    if (this.state.errBlock) {
        submitErr = <SnackbarContent icon="info_outline" close message="Please enter a valid email" color="danger" />;
    }
    return (
        <div>
            {submitErr}
        <div className={classes.section} style={{backgroundColor: !onContactPage ? "#A1AADA" : 'default', backgroundImage: !onContactPage ? "linear-gradient(315deg, #A1AADA 0%, #d9d9d9 100%)" : "none" , marginTop: !onContactPage ? "20px" : 0, marginBottom: !onContactPage ? "20px" : 0 }}>
        <GridContainer justify="center">
            {!this.state.contactPageSuccess ? (
                <>
                    <GridItem lg="6">
                    <h2 style={{marginTop: "auto", marginBottom: "auto", display: onContactPage ? 'none' : 'default' }} className={classes.title}>Get our systematic investing report</h2>
                    </GridItem>
                    <Form inline className="pt-1" onSubmit={(e) => this.sendEmail(e)}>
                        <GridItem lg={6} md={6}>
                            <Form.Label htmlFor="inlineFormInputName2" srOnly>
                                Email
                            </Form.Label>
                            <Form.Control
                                onChange={(e) => this.setState({email: e.target.value})}
                                style={{minWidth: "250px", height: "40px"}}
                                id="inlineFormInputName2"
                                placeholder="Your email..."
                                autoComplete="off"
                                type="email"
                                required
                            />
                        </GridItem>
                        <GridItem lg={6} md={6}>
                            <Button type="submit" className={classes.submitBtn} style={{ height: "38px"}} variant="danger" >
                                Submit
                            </Button>
                        </GridItem>
                    </Form>
                </>
            ) : (
                <CheckCircleIcon style={{ fill: "green" }} />
            )}
            </GridContainer>
        </div>
        </div>
    );
  }
}

export default withRouter(withStyles(styles)(KitSection))